import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ScreenSizeService } from '../../../../services/screen-size.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ranch-about-section-areas',
  templateUrl: './about-section-areas.component.html',
  styleUrls: ['./about-section-areas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutSectionAreasComponent implements OnInit, OnDestroy {
  slideData: any[];
  slideConfig: SwiperConfigInterface;
  customClass: string;
  isDesktop: boolean;
  isLtTablet: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private screenService: ScreenSizeService) {
    this.setScreen();
  }

  ngOnInit(): void {
    this.setSlider();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setScreen(): void {
    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.isDesktop = res;
    });
    this.screenService.isLtTablet$.pipe(takeUntil(this.destroy$)).subscribe(resp => {
      this.isLtTablet = resp;
    });
  }

  setSlider(): void {
    this.slideConfig = {
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide'
      },
      direction: 'horizontal',
      slidesPerView: 1,
      keyboard: true,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      loop: true,
      autoHeight: false,
      centeredSlides: true,
      effect: 'fade',
      pagination: {
        el: '.pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">0' + (index + 1) + '</span>';
        }
      }
    };
    this.slideData = [
      {
        url: '/assets/images/About_Slide_Different.jpg',
        caption1: 'What Sets Us Apart',
        caption2:
          'As a family-owned builder, we understand the importance of family, and strive to build every home as we would build our own. The exacting standards we use speak for themselves within our award-winning designs and authenticity.',
        btnCopy: 'Learn More on KHOV.com',
        btnLink: 'https://www.khov.com/why-choose-k-hovnanian/?ref=TheRanchWebsite'
      },
      {
        url: '/assets/images/About_Slide_History.jpg',
        caption1: 'Our History of Home',
        caption2:
          'In 1959, three brothers in a New Jersey trailer started a homebuilding business. Nearly a decade later, Hovnanian Enterprises®, Inc. was founded, launching a company that is widely known today.',
        btnCopy: 'Learn More on KHOV.com',
        btnLink: 'https://www.khov.com/why-choose-k-hovnanian/?ref=TheRanchWebsite'
      },
      {
        url: '/assets/images/About_Slide_Success.jpg',
        caption1: 'Strengthened by Success',
        caption2:
          'It is important to know that the company building your home has a sound legacy of business success. Hovnanian Enterprises®, Inc. began trading on the New York Stock Exchange 20 years ago and ranks among the top homebuilding companies in America.',
        btnCopy: 'Learn More on KHOV.com',
        btnLink: 'https://www.khov.com/why-choose-k-hovnanian/?ref=TheRanchWebsite'
      }
    ];
  }
}
