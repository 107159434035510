import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutOverviewPageComponent } from './features/about-overview/containers/about-overview-page/about-overview-page.component';
import { RanchCommunityComponent } from './features/community/containers/community/community.component';
import { FindAHomeComponent } from './features/find-a-home/find-a-home.component';
import { ViewHomeComponent } from './features/find-a-home/subfeatures/view-home/view-home.component';
import { RanchGalleryComponent } from './features/gallery/containers/gallery/gallery.component';
import { HomeComponent } from './features/home/containers/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'about-overview',
    component: AboutOverviewPageComponent
  },
  {
    path: 'gallery',
    component: RanchGalleryComponent
  },
  {
    path: 'community',
    component: RanchCommunityComponent
  },
  {
    path: 'find-a-home',
    children: [
      {
        path: '',
        component: FindAHomeComponent
      },
      {
        path: ':planName',
        component: ViewHomeComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
