import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { CommunityAmenityCard } from 'src/app/types/community.types';
import { ComponentBase } from 'src/app/utils/base-conmponent.class';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'ranch-community-amenities',
    templateUrl: './amenities.component.html',
    styleUrls: ['./amenities.component.scss']
})
export class RanchCommunityAmenitiesComponent extends ComponentBase implements OnInit {

    @Input() amenities: Array<CommunityAmenityCard> = [];

    constructor(
        private screenService: ScreenSizeService,
        private navigationService: NavigationService,
    ) {
        super(screenService, navigationService);
    }

    ngOnInit(): void {
    }

    getImageForAmenity(idx: number): string {
        const url = this.isDesktop ? this.amenities[idx].img : this.amenities[idx].imgMobile;
        return `url(${url})`;
    }
}
