import { Component, OnInit } from '@angular/core';
import { AreaAmenityMapConfig, CommunityConfig } from 'src/app/types/community.types';
import { CommunityAreaMapConfig, CommunityConfigData } from 'src/app/data/community-data';
import { ComponentBase } from 'src/app/utils/base-conmponent.class';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'ranch-gallery',
    templateUrl: './community.component.html',
    styleUrls: ['./community.component.scss']
})
export class RanchCommunityComponent extends ComponentBase implements OnInit {

    communityConfig: CommunityConfig = CommunityConfigData;
    communityMapConfig: AreaAmenityMapConfig = CommunityAreaMapConfig;

    constructor(
        private screenService: ScreenSizeService,
        private navigationService: NavigationService,
        private title: Title,
    ) {
        super(screenService, navigationService);
    }

    ngOnInit(): void {
        this.title.setTitle('Community | The Ranch');
    }
}