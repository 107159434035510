<div
  *ngIf="!taglineOnly"
  class="gallery-header"
  [ngStyle]="{ 'background-image': 'url(' + bannerImageUrl + ')' }"
>
  <div class="gallery-header-title">Envision the Next Chapter of Your Life Unfolding Here</div>
</div>
<div class="gallery-tagline">
  Browse through our gallery to preview every part of what will soon make up The Ranch. These visual
  tools will help you see more clearly all of the excitement that's to come.
</div>
