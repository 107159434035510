import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScreenSizeService } from '../../services/screen-size.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { truncate } from '../../utils/truncate.utils';

@Component({
  selector: 'ranch-blog-card-horizontal',
  templateUrl: './blog-card-horizontal.component.html',
  styleUrls: ['./blog-card-horizontal.component.scss']
})
export class BlogCardHorizontalComponent implements OnInit, OnDestroy {
  @Input() blogData: any;
  header: string;
  content: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private screenService: ScreenSizeService, private router: Router) {}

  ngOnInit(): void {
    this.detectScreenSize();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  detectScreenSize(): void {
    this.screenService.isMobile$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.content = this.blogData.blogContent.split('<p>').join('').split('</p>')[0];
      if (res) {
        this.content = truncate(118, this.content);
        this.header = truncate(45, this.blogData.blogHeader);
      } else {
        this.content = truncate(180, this.content);
        this.header = truncate(50, this.blogData.blogHeader);
      }
    });
  }

  getBlogDetails(id): void {
    this.router.navigate(['blog', id]);
  }
}
