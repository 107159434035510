import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ranch-amenity-card',
  templateUrl: './amenity-card.component.html',
  styleUrls: ['./amenity-card.component.scss']
})
export class AmenityCardComponent implements OnInit {
  @Input() modal: boolean;
  @Input() amenityData: any;
  @Input() communityData: any;
  @Input() qmiData: any;
  @Input() sharyland: any;
  @Input() isQMI: boolean;
  constructor() {}

  ngOnInit(): void {}
}
