import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ranch-promo-card',
  templateUrl: './promo-card.component.html',
  styleUrls: ['./promo-card.component.scss']
})
export class PromoCardComponent implements OnInit {
  @Input() cardData: any;

  constructor() {}

  ngOnInit(): void {}
}
