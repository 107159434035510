<div
  *ngIf="isDesktop"
  class="community-header"
  [ngStyle]="{ 'background-image': 'url(' + bannerImageUrl + ')' }"
>
  <div class="community-header-title">Make an Escape to an Exceptional New Way of Living</div>
</div>
<ranch-hero [hero]="hero" *ngIf="!isDesktop"></ranch-hero>
<div class="header-layout-container">
  <div class="subheading-container">
    <div class="community-subheading">Fill Your Days With Fun and Freedom in Rancho Cordova</div>
    <div class="community-tagline">
      Introducing a master-planned community that disrupts your usual routine to deliver a more
      fulfilling everyday lifestyle. The Ranch seamlessly combines on-site outdoor activities with
      breathtaking natural scenery. Whether the day calls for a family adventure or some alone time
      to unwind, you can find it waiting just outside your door.
    </div>
    <div class="subheading-button-container">
      <div class="subheading-button" (click)="navigateTo('#amenities')">
        <span class="subheading-button-label">See the Amenities</span>
      </div>
      <div class="subheading-button" (click)="navigateTo('#site-plan')">
        <span class="subheading-button-label">See the Site Plan</span>
      </div>
      <div class="subheading-button" (click)="navigateTo('#area-map')">
        <span class="subheading-button-label">See the Area Map</span>
      </div>
    </div>
  </div>
</div>
