import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ranch-about-card',
  templateUrl: './about-card.component.html',
  styleUrls: ['./about-card.component.scss']
})
export class AboutCardComponent implements OnInit {
  @Input() cardData: any;
  showHover = false;

  constructor() {}

  ngOnInit(): void {}

  setHover(state): void {
    if (state) {
      this.showHover = true;
    } else {
      this.showHover = false;
    }
  }
}
