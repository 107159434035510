import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { Validators, FormBuilder, FormGroup, AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { ScrollService } from '../../../../services/scroll.service';
import { FormValidationService } from '../../../../services/form-validation.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormSubmissionModalComponent } from '../../../../common/form-submission-modal/form-submission-modal.component';
import { FormService } from '../../../../services/form.service';
import { ScreenSizeService } from '../../../../services/screen-size.service';
import { ViewEncapsulation } from '@angular/core';
import { platform } from 'os';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'ranch-menu-interest',
  templateUrl: './menu-interest.component.html',
  styleUrls: ['./menu-interest.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuInterestComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() interestData: any;
  @Input() forFeature: string;
  @Input() goToAfterSubmit: string;
  @Output() closeModal: EventEmitter<string> = new EventEmitter();
  interestForm: FormGroup;
  formIsSubmitted = false;
  sub: Subscription;
  isMobile: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();
  type: string;
  constructor(
    private screenService: ScreenSizeService,
    private fb: FormBuilder,
    private scrollService: ScrollService,
    private validationService: FormValidationService,
    private formService: FormService,
    private platform: PlatformService
  ) {
    this.setScreen();
  }

  ngOnInit(): void {
    this.initInterestForm();
  }

  ngAfterViewInit(): void {
    this.listenForScroll();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  close(): void {
    this.closeModal.emit('close');
  }

  setScreen(): void {
    this.screenService.isMobile$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.isMobile = res;
    });
  }

  listenForScroll(): void {
    this.sub = this.scrollService.anchorScroll$.subscribe(res => {
      if (res) {
        const form = document.getElementById('interest-form-id');
        const offset = 70;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elRect = form.getBoundingClientRect().top;
        const elPos = elRect - bodyRect;
        const anchorPos = elPos - offset;
        if (this.platform.isBrowser()) {
          window.scrollTo({
            top: anchorPos,
            behavior: 'smooth'
          });
          this.scrollService.anchorScroll$.next(false)
        };
      }
    });
  }

  submit(): void {
    this.formIsSubmitted = true;
    if (this.interestForm.valid) {
      this.formService.submitForm(this.interestForm.value).subscribe(res => {
        if (this.forFeature === 'interest-modal') {
          this.closeModal.emit('close');
        }
        this.type = 'thanks';
        this.interestForm.reset();
        this.formIsSubmitted = false;
      });
    } else {
      // handle errors here
    }
  }

  showErrors(field: AbstractControl): boolean {
    return field.invalid && (field.touched || this.formIsSubmitted);
  }

  initInterestForm(): void {
    this.interestForm = this.fb.group({
      fname: [null, Validators.required],
      lname: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, this.validationService.isValidEmail])],
      phone: [null, Validators.compose([this.validationService.isValidPhone])],
      consent: [null],
      interests: new FormGroup({
        sagebrush: new FormControl(false),
        springs: new FormControl(false),
        montrose: new FormControl(false),
        paseo: new FormControl(false),
        canyon: new FormControl(false)
      }, requireCheckboxesToBeCheckedValidator()),
      comments: [null],
      formidentifier: [
        this.forFeature === 'interest-modal' ? 'interest-inforequest' : 'interest-modalinforequest'
      ],
      community: 'The Ranch'
    });
  }
}

export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return function validate(formGroup: FormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];

      if (control.value === true) {
        checked ++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxesToBeChecked: true,
      };
    }

    return null;
  };
}