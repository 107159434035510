import { AfterViewInit, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'ranch-home-logo-text',
  templateUrl: './home-logo-text.component.html',
  styleUrls: ['./home-logo-text.component.scss']
})
export class HomeLogoTextComponent implements OnInit, AfterViewInit {
  @ViewChild('logoText', { static: false }) elemRef: ElementRef;
  customClass = 'home-logo';

  constructor(private platform: PlatformService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.detectItemsInView();
  }

  detectItemsInView(): void {
    if (!this.platform.isBrowser()) {
      return;
    }
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
          this.addFade('fade-logo-text');
        }
      }
    });
    observer.observe(this.elemRef.nativeElement);
  }

  addFade(id): void {
    const el = document.getElementById(id);
    if (el) {
      el.classList.add('fade');
    }
  }
}
