<div id="area-map" class="area-map-header-container">
  <div class="area-map-header">Explore the<br *ngIf="!isDesktop" />&nbsp;Surrounding Area</div>
  <div class="area-map-tagline">
    Popular local destinations have been categorized for your convenience and can be viewed on the
    map below. Simply select from the list to see what is available nearby in each category.
  </div>
</div>
<div class="area-map-container">
  <div class="area-map-legend-container">
    <div class="area-map-legend-category" *ngFor="let category of areaAmenities; index as i">
      <div class="category-label-container" (click)="selectCategory(i)">
        <img class="category-icon" [src]="category.iconCategory" />
        <span class="category-label">{{ category.label }}</span>
        <img class="category-toggle-icon" [src]="i === currentCategory ? subIcon : plusIcon" />
      </div>
      <div
        class="row-collapse-container"
        [ngClass]="{ 'row-collapse-container-open': i === currentCategory }"
      >
        <div
          class="area-map-legend-row"
          *ngFor="let item of category.items; index as i"
          (click)="toggleItemActive(i)"
        >
          <!-- This ensures both box images load -->
          <img
            class="legend-checkbox"
            [src]="checkboxSelected"
            [ngStyle]="{ display: isItemActive(i) ? 'unset' : 'none' }"
          />
          <img
            class="legend-checkbox"
            [src]="checkboxUnselected"
            [ngStyle]="{ display: isItemActive(i) ? 'none' : 'unset' }"
          />
          <div class="legend-label">
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <google-map
    #map
    [height]="mapConfig.height"
    [width]="mapConfig.width"
    [center]="mapConfig.center"
    [zoom]="mapConfig.zoom"
    [options]="mapConfig.options"
  >
    <map-marker
      #ranchMarker
      [position]="ranchPosition"
      [options]="{ icon: ranchPinIcon }"
      (mapClick)="openRanchInfoWindow()"
    ></map-marker>
    <map-info-window #ranchInfoWindow>
      <div class="info-window-content">
        <div class="info-window-logo">
          <img [src]="ranchLogo" />
        </div>
        <div class="info-window-text-content">
          <div class="info-window-address">
            {{ ranchAddress.address1 }}
          </div>
          <div class="info-window-address">
            {{ ranchAddress.address2 }}
          </div>
          <div class="info-window-address">
            {{ ranchAddress.city }} {{ ranchAddress.stateLabel }} {{ ranchAddress.zip }}
          </div>
          <div class="info-window-tel">
            {{ ranchAddress.telephone }}
          </div>
          <div class="info-window-directions" (click)="navigateTo(ranchAddress.directions)">
            Get Directions
          </div>
        </div>
      </div>
    </map-info-window>
    <ng-container *ngIf="currentCategory > -1">
      <map-marker
        #markers
        *ngFor="let item of getActiveItems(); index as i"
        [position]="{ lat: item.address.latitude, lng: item.address.longitude }"
        [options]="{ icon: getCurrentCategoryIcon() }"
        (mapClick)="openInfoWindow(i)"
      ></map-marker>
      <map-info-window #infoWindows *ngFor="let item of getActiveItems(); index as i">
        <div class="info-window-content">
          <div class="info-window-text-content">
            <div class="info-window-title">
              {{ item.name }}
            </div>
            <div class="info-window-address">
              {{ item.address.address1 }}
            </div>
            <div *ngIf="!!item.address.address2" class="info-window-address">
              {{ item.address.address2 }}
            </div>
            <div class="info-window-address">
              {{ item.address.city }} {{ ranchAddress.stateLabel }} {{ ranchAddress.zip }}
            </div>
            <div class="info-window-tel">{{ item.phone }} {{ item.phoneExt }}</div>
            <div class="info-window-directions" (click)="navigateTo(item.address.directions)">
              Get Directions
            </div>
          </div>
        </div>
      </map-info-window>
    </ng-container>
  </google-map>
</div>
