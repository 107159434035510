<div *ngIf="config" class="view-home-gallery-container">
  <ranch-tab-nav [tabs]="config.tabs" [selected]="selectedTab" (onSetTab)="setTabHandler($event)">
    <ranch-gallery-carousel
      *ngIf="selectedTab === 0"
      [images]="config.photos"
    ></ranch-gallery-carousel>
    <ranch-gallery-carousel
      *ngIf="selectedTab === 1"
      [images]="config.floorplanImages"
    ></ranch-gallery-carousel>
  </ranch-tab-nav>
</div>
