<div *ngIf="images[index]" class="carousel-container">
  <div class="photo-container">
    <div class="photo" [ngStyle]="{ 'background-image': 'url(' + images[index].src + ')' }"></div>
    <div *ngIf="images[index].caption" class="photo-caption">
      <span class="photo-caption-text">{{ images[index].caption }}</span>
    </div>
  </div>
  <div class="nav-container">
    <div class="nav-next" (click)="onNext()">
      <img src="/assets/icons/CarouselArrow-Right_Dark.svg" />
    </div>
    <div class="nav-prev" (click)="onPrev()">
      <img src="/assets/icons/CarouselArrow-Left_White.svg" />
    </div>
  </div>
</div>
