import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ScreenSizeService } from '../../../../services/screen-size.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ranch-home-section-areas',
  templateUrl: './home-section-areas.component.html',
  styleUrls: ['./home-section-areas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeSectionAreasComponent implements OnInit, OnDestroy {
  slideData: any[];
  slideConfig: SwiperConfigInterface;
  customClass: string;
  isDesktop: boolean;
  isLtTablet: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private screenService: ScreenSizeService) {
    this.setScreen();
  }

  ngOnInit(): void {
    this.setSlider();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setScreen(): void {
    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.isDesktop = res;
    });
    this.screenService.isLtTablet$.pipe(takeUntil(this.destroy$)).subscribe(resp => {
      this.isLtTablet = resp;
    });
  }

  setSlider(): void {
    this.slideConfig = {
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide'
      },
      direction: 'horizontal',
      slidesPerView: 1,
      keyboard: true,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      loop: true,
      autoHeight: false,
      centeredSlides: true,
      effect: 'fade',
      pagination: {
        el: '.pagination',
        clickable: true,
        renderBullet(index, className) {
          return '<span class="' + className + '">0' + (index + 1) + '</span>';
        }
      }
    };
    this.slideData = [
      {
        url: `${environment.S3_URL}images/Home_Slide_Canyon.png`,
        caption1: 'Canyon at The Ranch',
        caption2:
          'Discover comfort and elegance at Canyon at The Ranch. Spacious open floor plans up to 2,752 sq ft, including Extra Suite Options for long-term guests. Find your dream home with a pool, home office, or game room. Explore innovative Looks for curated interior design that matches your lifestyle.'
      },
      {
        url: '/assets/images/Home_Slide_Montrose.jpg',
        caption1: 'Montrose at The Ranch',
        caption2:
          'Maximize your space in these stunningly well-designed single family homes. Each floor plan in this collection can be personalized to include up to 2,628 square feet and six bedrooms, plus optional lofts and dens.'
      },
      {
        url: `${environment.S3_URL}images/Home_Slide_Paseo.png`,
        caption1: 'Paseo at The Ranch',
        caption2:
          'Elevate your lifestyle at Paseo at The Ranch. Discover stylish single-family homes designed for maximum space and comfort. Choose from four layouts offering up to 4 bedrooms, 2,028 sq ft, and inviting covered porches for a truly welcoming atmosphere.'
      },
      {
        url: '/assets/images/Home_Slide_Sagebrush.jpg',
        caption1: 'Sagebrush at The Ranch',
        caption2:
          'Achieve a stylish, low-maintenance lifestyle with this main-level living collection. You can enjoy up to 2,014 square feet and four bedrooms all on one level — as well as an Extra Suite Plus option for these designs.'
      }
      /*{
        url: '/assets/images/Home_Slide_Springs.jpg',
        caption1: 'Springs at The Ranch',
        caption2: 'With both one- and two-level plans available, this collection offers unmatched variety. Select a design that matches your unique style for a living space that offers up to 2,152 square feet, five bedrooms and a loft.'
      }*/
    ];
  }
}
