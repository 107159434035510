export type ImageWithCaption = {
  src: string;
  caption: string;
};

export type GalleryConfig = {
  tabs: Array<string>;
  photos: Array<ImageWithCaption>;
  floorplanImages: Array<ImageWithCaption>;
};

export const GalleryData: GalleryConfig = {
  tabs: ['Photos', 'Floor Plan'],
  photos: [],
  floorplanImages: []
};
