import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ranch-gallery-tour-embed',
    templateUrl: './tour.component.html',
    styleUrls: ['./tour.component.scss']
})
export class RanchGalleryTourComponent implements OnInit {

    @Input() iframeUrls: Array<string> = [];
    index = 0;

    constructor(
        private observer: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
    }

    onPrev(): void {
        if (this.index > 0) {
            this.index--;
            this.observer.detectChanges();
        }
    }
    onNext(): void {
        if (this.index < this.iframeUrls.length - 1) {
            this.index++;
            this.observer.detectChanges();
        }
    }
}