import { Component, OnInit, ViewChild, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ScreenSizeService } from '../../../../services/screen-size.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ranch-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeSliderComponent implements OnInit, OnDestroy {
  @Input() slideData: any;
  @Input() slideConfig: SwiperConfigInterface;
  @Input() customClass: string;
  @ViewChild('swiperComp', { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;
  currentIndex = 0;
  isDesktop: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private screenService: ScreenSizeService
  ) {
    this.detectScreenSize();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  detectScreenSize(): void {
    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.isDesktop = res;
    });
  }

  onIndexChange(evt): void {
    this.currentIndex = evt;
  }
}
