import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LeafletService {
    public L = null;

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
      if (isPlatformBrowser(platformId)) {
        this.L = require('leaflet');
      }
    }
}
