import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'ranch-section-intro',
  templateUrl: './section-intro.component.html',
  styleUrls: ['./section-intro.component.scss']
})
export class SectionIntroComponent implements OnInit, OnChanges {
  @Input() customClass: string;
  @Input() introHeading: string;
  @Input() introCopy: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {}
}