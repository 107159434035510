<div #historySection class="contain-history-section">
    <div class="history-half history-left">
        <div class="contain-history-text">
            <div id="fade-history-head-all-0" class="head-text-all delay">
            <div *ngIf="isDesktop" class="contain-head">
                <div class="head">our texas</div>
                <div class="spacer"></div>
            </div>
            <div *ngIf="isDesktop" class="contain-head">
                <div class="spacer"></div>
                <div class="head">history</div>
                <div class="head-salsa">lives</div>
            </div>
            <div *ngIf="!isDesktop" class="contain-head mobile-head">
                <div class="head">our texas history</div>
            </div>
            <div *ngIf="!isDesktop" class="contain-head contain-mobile-lives">
                <div class="head-salsa mobile-lives">lives</div>
            </div>
        </div>
            <div id="fade-history-text-1" class="history-text delay">
                Since our founder, O. W. Killam came to Texas
    in the early 1900s and became an icon of the state's international influence, our family-owned company has embraced and fostered the best Texas traditions... family, generosity and opportunity. Those same values are what Winfield Communities are built on today.
            </div>
            <a id="fade-killam-btn-2" target="_blank" class="kill-btn delay" href="http://www.killamdevelopment.com/">
                <div class="btn-text">
                    killam development
                </div>
            </a>
        </div>
    </div>
    <div class="history-half">
        <div class="history-img">
            <img src="/assets/images/texan-future.jpg">
        </div>
    </div>
</div>