import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'ranch-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.scss']
})
export class GalleryModalComponent implements OnInit {
  slideData: any;
  slideConfig: SwiperConfigInterface;
  startingIndex: number;
  customClass: string;

  constructor(
    private dialogRef: MatDialogRef<GalleryModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.slideData = data.slideData;
    this.startingIndex = data.index;
  }

  ngOnInit(): void {
    this.setSwiperConfig();
  }

  setSwiperConfig(): void {
    this.slideConfig = {
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide'
      },
      direction: 'horizontal',
      slidesPerView: 1,
      keyboard: true,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: false,
      loop: true,
      autoHeight: true,
      centeredSlides: true,
      initialSlide: this.startingIndex
    };
    this.customClass = 'gallery-modal';
  }

  close(): void {
    this.dialogRef.close();
  }
}
