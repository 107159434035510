export const SPAN_1_STYLES =
  'font-size: 32px; font-weight: 500; letter-spacing: 0; line-height: 37px;';

export const SPAN_2_STYLES =
  'font-size: 18px; letter-spacing: 2px; line-height: 23px; text-transform: uppercase; font-family: Gabriel Sans; font-weight: 700;';

export const SPAN_3_STYLES = SPAN_2_STYLES + ' position: relative; top: -15px;';

export const SPAN_4_STYLES =
  'font-size: 18px; letter-spacing: 0; line-height: 22px; font-family: Gabriel Sans; position: relative; top: -20px; font-weight: 500;';

export const UNDERLINE_STYLE =
  'height: 2px; width: 100%; background: #FFFFFF; position: relative; top: -5px;';

export const DESKTOP_SPAN_1_STYLES =
  'font-size: 45px; font-weight: 500; letter-spacing: 0; line-height: 50px;';

export const DESKTOP_SPAN_2_STYLES =
  'font-size: 20px; letter-spacing: 2px; line-height: 24px; text-transform: uppercase; font-family: Gabriel Sans; font-weight: 700;';

export const DESKTOP_SPAN_3_STYLES = DESKTOP_SPAN_2_STYLES + ' position: relative; top: -15px;';

export const DESKTOP_SPAN_4_STYLES =
  'font-size: 18px; letter-spacing: 0; line-height: 22px; font-family: Gabriel Sans; position: relative; top: -20px; font-weight: 500;';

export const DESKTOP_UNDERLINE_STYLE =
  'height: 2px; width: 100%; background: #FFFFFF; position: relative; top: -5px;';
