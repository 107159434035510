import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AmenitiesService {
  chosenAmenId$ = new BehaviorSubject(1);
  amenityIndex$ = new BehaviorSubject(0);

  constructor() { }
}
