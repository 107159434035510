import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GalleryConfig } from 'src/app/data/view-home.data';

@Component({
  selector: 'view-home-gallery',
  templateUrl: './view-home-gallery.component.html',
  styleUrls: ['./view-home-gallery.component.scss']
})
export class ViewHomeGalleryComponent implements OnInit {
  selectedTab: number = 0;

  @Input() config: GalleryConfig;

  constructor(private sanitizer: DomSanitizer, private observer: ChangeDetectorRef) {}

  ngOnInit(): void {}

  setTabHandler(tabNumber: number): void {
    this.selectedTab = tabNumber;
    this.observer.detectChanges();
  }
}
