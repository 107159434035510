import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ranch-product-promo-card',
  templateUrl: './product-promo-card.component.html',
  styleUrls: ['./product-promo-card.component.scss']
})
export class ProductPromoCardComponent implements OnInit {
  @Input() cardData: any;
  constructor() {}

  ngOnInit(): void {}
}
