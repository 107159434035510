<div class="contain-card">
  <div class="eaves-head">
    {{ cardData.eavesHead }}
  </div>
  <div class="salsa">
    {{ cardData.salsaText }}
  </div>
  <div class="eaves-text">
    {{ cardData.subhead }}
  </div>
  <div class="btn-container">
    <a routerLink="{{ cardData.routerLink }}" class="red-button">{{ cardData.btnText }}</a>
  </div>
</div>
