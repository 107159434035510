import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { PlatformService } from 'src/app/services/platform.service';
import { environment } from 'src/environments/environment';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ComponentBase } from 'src/app/utils/base-conmponent.class';

@Component({
  selector: 'ranch-home-section-amenities',
  animations: [
    trigger('hidVis', [
      state('hid', style({
        opacity: 0,
        transform: 'translateY(100%)'
      })),
      state('vis', style({
        opacity: 1,
        transform: 'translateY(0%)'
      })),
      transition('hid => vis', [
        animate('0.6s')
      ])
    ])
  ],
  templateUrl: './home-section-amenities.component.html',
  styleUrls: ['./home-section-amenities.component.scss']
})
export class HomeSectionAmenitiesComponent extends ComponentBase implements OnInit, AfterViewInit {
  @ViewChild('homeAmenities', { static: false }) elemRef: ElementRef;
  showAmenities = false;
  S3_URL: string = environment.S3_URL;
  
  constructor(
    private platform: PlatformService,
    private screenService: ScreenSizeService,
    private navigationService: NavigationService
  ) {
    super(screenService, navigationService);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.detectItemsInView();
  }

  turnVis() {
    this.showAmenities = true;
  }

  detectItemsInView(): void {
    if (!this.platform.isBrowser()) {
      return;
    }
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
          this.turnVis();
        }
      }
    });
    observer.observe(this.elemRef.nativeElement);
  }
}
