<ranch-nav-spacer></ranch-nav-spacer>
<div
  class="contain-hero"
  [ngClass]="findClass()"
  [style]="'background-image: url(' + (isDesktop ? hero.heroImg : hero.heroImgMobile) + ')'"
>
  <div class="mask"></div>
  <div id="fade-about-hero-header-0" class="hero-header">
    <div *ngIf="isDesktop ? hero.text : hero.mobileText" class="container">
      <h1
        class="heading"
        [innerHTML]="
          isDesktop ? sanitizeHTML(hero.text.heroText) : sanitizeHTML(hero.mobileText.heroText)
        "
      ></h1>
    </div>
  </div>
</div>
