import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ScreenSizeService } from '../../../../services/screen-size.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TeamData } from '../../../../data/team-data';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'ranch-team-section',
  templateUrl: './team-section.component.html',
  styleUrls: ['./team-section.component.scss']
})
export class TeamSectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('teamSection', { static: false }) elemRef: ElementRef;
  isDesktop: boolean;
  isLtTablet: boolean;
  slideData = TeamData;
  slideConfig: SwiperConfigInterface;
  customClass: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private screenService: ScreenSizeService, private platform: PlatformService) {
    this.setScreen();
  }

  ngOnInit(): void {
    this.setSlideConfig();
  }

  ngAfterViewInit(): void {
    this.detectItemsInView();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setScreen(): void {
    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.isDesktop = res;
    });
    this.screenService.isLtTablet$.pipe(takeUntil(this.destroy$)).subscribe(resp => {
      this.isLtTablet = resp;
    });
  }

  detectItemsInView(): void {
    if (!this.platform.isBrowser()) {
      return;
    }
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
          this.addFade('fade-team-slide-head-0');
          this.addFade('fade-team-slide-subhead-1');
          this.addFade('fade-team-slider-2');
        }
      }
    });
    observer.observe(this.elemRef.nativeElement);
  }

  addFade(id): void {
    const el = document.getElementById(id);
    if (el) {
      if (id.includes('0')) {
        el.classList.add('fade');
      } else if (id.includes('1')) {
        el.classList.add('fade-0');
      } else if (id.includes('2')) {
        el.classList.add('fade-1');
      }
    }
  }

  setSlideConfig(): void {
    this.slideConfig = {
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide'
      },
      direction: 'horizontal',
      slidesPerView: 1,
      keyboard: true,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
        clickable: true,
        hideOnClick: false
      },
      loop: true
    };
    this.customClass = 'team-mobile';
  }
}
