import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ranch-map-card-simple',
  templateUrl: './map-card-simple.component.html',
  styleUrls: ['./map-card-simple.component.scss']
})
export class MapCardSimpleComponent implements OnInit {
  @Input() modal: boolean;

  constructor() {}

  ngOnInit(): void {}
}
