import {
  Component,
  AfterViewInit,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { MenuInterestComponent } from './features/navigation/presentational/menu-interest/menu-interest.component';
import { Router, NavigationEnd, RouterEvent, ActivatedRoute } from '@angular/router';
import { ScrollService } from './services/scroll.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { PlatformService } from './services/platform.service';
import { Pages, SEOParams } from './data/seo.data';
import { SeoHelperService } from './services/seo-helper.service';
import { HomesService } from './services/homes.service';
import { ComponentBase } from './utils/base-conmponent.class';
import { NavigationService } from './services/navigation.service';
import { ScreenSizeService } from './services/screen-size.service';
import { NavigationComponent } from './features/navigation/navigation.component';

@Component({
  selector: 'ranch-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends ComponentBase implements OnInit, AfterViewInit, OnDestroy {
  title = 'The Ranch';
  freeze: boolean;
  opaque: boolean;
  opened: boolean;
  scrolledPast = false;
  isHome: boolean = true;
  menuType = 'menu';
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('sidenav') public sidenav: MatSidenav;
  @ViewChild('navigation') public nav: NavigationComponent;
  @ViewChild(MenuInterestComponent) interest;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private scrollService: ScrollService,
    private platform: PlatformService,
    private seoHelperService: SeoHelperService,
    private homesService: HomesService,
    private navigationService: NavigationService,
    private screenService: ScreenSizeService,
    private observer: ChangeDetectorRef
  ) {
    super(screenService, navigationService);
    this.checkForAndStoreUtmParams();
    this.listenForNavEnd();
  }

  ngOnInit() {
    this.listenForScrollFreeze();
    this.listenForOpacity();
  }

  ngAfterViewInit() {
    this.scrollService.scrollTopOnNavigation();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  receiveMessage($event) {
    if ($event === 'form' || $event === 'menu') {
      this.menuType = $event;
      this.interest.type = $event;
      this.sidenav.toggle();
    }
    if ($event === 'close') {
      this.sidenav.close();
    }
  }

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    if (!this.platform.isBrowser()) {
      return;
    }
    const navOffset = 70;
    if (window.pageYOffset >= navOffset) {
      this.scrolledPast = true;
    } else {
      this.scrolledPast = false;
    }
  }

  listenForNavEnd(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.length > 1) {
          this.isHome = false;
          this.observer.detectChanges();
        } else {
          this.scrolledPast = false;
          this.isHome = true;
          this.observer.detectChanges();
        }
        this.resetScrollAndNav();
        this.manipulatePageMetadata(event);
      }
    });
  }

  resetScrollAndNav(): void {
    this.freeze = false;
    this.opaque = false;
  }

  listenForScrollFreeze(): void {
    this.scrollService.scrollFreeze$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.freeze = res;
    });
  }

  listenForOpacity(): void {
    this.scrollService.navOpaque$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.opaque = res;
    });
  }

  lockScroll(type): void {
    if (!this.platform.isBrowser()) {
      return;
    }
    const el = document.getElementsByTagName('html')[0];
    let dist;
    if (type === 'open') {
      dist = window.scrollY;
      el.style.cssText = 'left: 0px; top: -' + dist + 'px;';
      el.classList.add('cdk-global-scrollblock');
    } else {
      dist = Math.abs(el.offsetTop);
      el.classList.remove('cdk-global-scrollblock');
      el.scrollTo(0, dist);
      el.removeAttribute('style');
    }
  }

  private checkForAndStoreUtmParams() {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        if (!!window && !!window.sessionStorage) {
          const { utm_source, utm_medium, utm_campaign, utm_content, ref } =
            this.route.snapshot.queryParams;
          if (
            !!utm_source &&
            utm_source !== '' &&
            !!utm_medium &&
            utm_medium !== '' &&
            !!utm_campaign &&
            utm_campaign !== ''
          ) {
            sessionStorage.setItem('utm_source', utm_source);
            sessionStorage.setItem('utm_medium', utm_medium);
            sessionStorage.setItem('utm_campaign', utm_campaign);
            if (!!utm_content && utm_content !== '') {
              sessionStorage.setItem('utm_content', utm_content);
            }
          }
          if (!!ref) {
            sessionStorage.setItem('ref', ref);
          }
        }
      }
    });
  }
 
  private manipulatePageMetadata(event: NavigationEnd): void {
    const topLevelRoute = this.route?.firstChild;
    switch (topLevelRoute.routeConfig.path) {
      case Pages.Home: {
        const params = SEOParams[Pages.Home];
        this.seoHelperService.setPageData(params.title, params.body, event);
      };
      case Pages.Community: {
        const params = SEOParams[Pages.Community];
        this.seoHelperService.setPageData(params.title, params.body, event);
      };
      case Pages.About: {
        const params = SEOParams[Pages.About];
        this.seoHelperService.setPageData(params.title, params.body, event);
      };
      case Pages.FindAHome: {
        const homesPageRoute = topLevelRoute?.firstChild;
        switch (homesPageRoute?.routeConfig.path) {
          case '': {
            const params = SEOParams[Pages.FindAHome];
            this.seoHelperService.setPageData(params.title, params.body, event);
          };
          case ':planName': {
            const { planName } = homesPageRoute.snapshot.params;
            this.homesService.getHomeByPlanName(planName).subscribe(home => {
              const title = home?.PlanName || '';
              const desc = home?.Description || '';
              this.seoHelperService.setPageData(title, desc, event);
            });
          }
        }
      };
    }
  }

}
