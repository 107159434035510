<ng-container *ngIf="communityData; else elseTemplate">
    <div (click)="navToCommunity(communityData.id)" class="contain-card temp-comm">
        <div class="card-top">
            <img src="{{ communityData.mainImgSrc }}" />
            <div *ngIf="communityData.hasSnipe" class="contain-snipe">
                <div class="snipe">
                    <div class="snipe-text">
                        {{ communityData.snipeText }}
                    </div>
                </div>
            </div>
        </div>
        <div class="card-bottom">
            <div class="com-name">{{ communityData.name }}</div>
            <div class="com-city">{{ communityData.cityState }}</div>
            <ng-container *ngIf="detailsComingSoon">
                <!-- <div class="bed-bath">
                        <div class="data-flex beds">
                            <div class="data-num">{{communityData.beds}}</div>
                            <div nclass="data-text">Beds</div>
                        </div>
                        <div class="data-flex baths">
                            <div class="data-num">{{communityData.baths}}</div>
                            <div class="data-text">Baths</div>
                        </div>
                    </div>
                    <div class="data-flex">
                        <div class="data-num">{{communityData.sqft}}</div>
                        <div class="data-text">sq. ft.</div>
                    </div> -->
            </ng-container>
            <!-- <div *ngIf="detailsComingSoon" class="coming-soon">
                    Anticipated Opening in 2022
                </div> -->
            <!-- <div class="data-flex" [ngClass]="{ 'top-margin' : detailsComingSoon}">
                    <div class="data-text">Single-Family Homes Priced from</div>
                    <div class="data-num price">{{communityData.singleHomesData.price}}</div>
                </div> -->
            <div class="data-flex">
                <div class="data-text bold">
                    Single-Family Homes and <br /> Townhomes Coming Soon
                </div>
                <!-- <div class="data-num price">{{communityData.townHomesData.price}}</div> -->
            </div>
            <div class="contain-action">
                <div class="action-text">Request Info</div>
                <img src="/assets/icons/Right_Arrow_Red.svg" />
            </div>
        </div>
    </div>
</ng-container>
<ng-template #elseTemplate>
    <div (click)="navToQMI(qmiData.lotNum)" class="contain-card">
        <div class="card-top">
            <div class="icons">
                <p><img *ngIf="qmiData.underConstruction" src="/assets/icons/Construction_Icon_wCircle.svg"/><span class="text">Under Construction</span></p>
                <p><img *ngIf="qmiData.personalization" src="/assets/icons/Personalization_Icon_wCircle.svg"/><span class="text">Personalization Available</span></p>
            </div>
            <img src="/assets/images/Lot-{{ qmiData.lotNum }}.png" />
        </div>
        <div class="card-bottom">
            <div class="com-name">{{ qmiData.homePlanName }}</div>
            <div class="com-city">{{ qmiData.streetaddress }}</div>
            <div class="com-city">{{ qmiData.commName }}</div>

            <div class="bed-bath">
                <div class="data-flex beds">
                    <div class="data-num">{{ qmiData.lotNum }}</div>
                    <div class="data-text">Lot No.</div>
                </div>
                <div class="data-flex baths">
                    <div class="data-num">{{ qmiData.beds }}</div>
                    <div class="data-text">Beds</div>
                </div>
                <div class="data-flex baths last">
                    <div class="data-num">{{ qmiData.baths }}</div>
                    <div class="data-text">Baths</div>
                </div>
            </div>

            <div class="bed-bath qmi-garage">
                <div class="data-flex beds">
                    <div class="data-num">{{ qmiData.sqft }}</div>
                    <div class="data-text">sq. ft.</div>
                </div>
                <div class="data-flex garage">
                    <div class="data-num">{{ qmiData.carGarage }}-Car</div>
                    <div class="data-text">Garage</div>
                </div>
                <br class="desktop-hide" />
                <div class="data-flex date">
                    <div class="data-num">{{ qmiData.availability }}</div>
                    <div class="data-text">Availability</div>
                </div>
            </div>
            <div *ngIf="qmiData.lotNum === 15 || qmiData.lotNum === 34" class="data-flex garage">
                <div class="data-text">{{ qmiData.homeType }} priced at</div>
                <div class="data-num">{{ qmiData.pricing }}</div>
            </div>
            <div *ngIf="(qmiData.lotNum !== 15 && qmiData.lotNum !== 34)" class="data-flex garage">
                <div class="data-text">{{ qmiData.homeType }} priced from the</div>
                <div class="data-num">{{ qmiData.pricing }}</div>
            </div>
            <div class="contain-action">
                <div class="action-text">more details</div>
                <img src="/assets/icons/Right_Arrow_Red.svg" />
            </div>
        </div>
    </div>

</ng-template>