export enum FilterNames {
  PriceRange = 'price-range',
  Beds = 'beds',
  Baths = 'baths',
  SqFt = 'sq-ft',
  Garages = 'garages',
  Stories = 'stories',
  HomeType = 'home-type',
  Community = 'community',
  ExtraSuite = 'extra-suite',
  MainLevelOwners = 'main-level-owners'
}

export enum FilterPopupTypes {
  Range = 'range',
  Boxes = 'boxes',
  List = 'list'
}

export interface DesktopFilter {
  name: FilterNames;
  label: string;
  width?: number;
  isFullWidth?: boolean,
  isCheckbox?: boolean;
  helper?: string;
  popupConfig: {
    type?: FilterPopupTypes;
    placeholder?: string,
    options1?: string[];
    options2?: string[];
    options?: string[];
    getOptions?: string;
  };
}
