import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ranch-gallery-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class RanchGalleryCarouselComponent implements OnInit {
  @Input() images: Array<{ src: string; caption: string }> = [];
  index = 0;

  constructor(private observer: ChangeDetectorRef) {}

  ngOnInit(): void {}

  onPrev(): void {
    if (this.index > 0) {
      this.index--;
    } else {
      this.index = this.images.length - 1;
    }
    this.observer.detectChanges();
  }
  onNext(): void {
    if (this.index < this.images.length - 1) {
      this.index++;
    } else {
      this.index = 0;
    }
    this.observer.detectChanges();
  }
}
