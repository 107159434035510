import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';
import { LatLngTuple, LeafletMouseEvent } from 'leaflet';
import { LeafletService } from 'src/app/services/leaflet.service';

import { NavigationService } from 'src/app/services/navigation.service';
import { PlatformService } from 'src/app/services/platform.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import {
  RanchSubdivisions,
  RegionPoints,
  SiteplanData,
  SiteplanRegionData
} from 'src/app/types/community.types';
import { ComponentBase } from 'src/app/utils/base-conmponent.class';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ranch-community-siteplan',
  templateUrl: './siteplan.component.html',
  styleUrls: ['./siteplan.component.scss']
})
export class RanchCommunitySiteplanComponent extends ComponentBase implements OnInit {
  RanchSubdivisions: typeof RanchSubdivisions = RanchSubdivisions;
  map: any;
  legendExpanded: boolean = this.isDesktop;
  legendHighlight: RanchSubdivisions | undefined;
  tooltipUrl: string = `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Site+Plan/ToolTip.svg`;
  legendClose: string = `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Site+Plan/SitePlanKey-Closed.svg`;
  activeRippleAnim: boolean = false;
  private points: Array<{ lat: number; lng: number }> = [];

  @Input() siteplanData: SiteplanData;

  @ViewChildren('popups') popups: QueryList<ElementRef>;

  constructor(
    private screenService: ScreenSizeService,
    private navigationService: NavigationService,
    private platform: PlatformService,
    private observer: ChangeDetectorRef,
    private leaflet: LeafletService
  ) {
    super(screenService, navigationService);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initMap();
  }

  initMap(): void {
    if (!this.platform.isBrowser()) {
      return;
    }

    const overlayPosition = this.siteplanData.overlayPosition;
    const bl = [overlayPosition.bl.lat, overlayPosition.bl.lng];
    const tr = [overlayPosition.tr.lat, overlayPosition.tr.lng];
    this.map = this.leaflet.L.map('map');
    this.map.zoomControl.setPosition('bottomright');
    this.map.setView(
      [this.siteplanData.center.lat, this.siteplanData.center.lng],
      this.siteplanData.zoom
    );
    this.map.setMinZoom(15);
    this.map.setMaxZoom(19);
    this.map.setMaxBounds([bl as LatLngTuple, tr as LatLngTuple]);
  
    if (!environment.production) {
      const window = (document.defaultView as any);
      if (!window.regionPoints) {
        window.regionPoints = [];
      }
      this.map.addEventListener('click', (e: LeafletMouseEvent) => {
        window.regionPoints.push({id: window.regionPoints.length, lat: e.latlng.lat, lng: e.latlng.lng});
        console.log(window.regionPoints);
      });
    }

    const overlay = this.leaflet.L.imageOverlay(this.siteplanData.overlayImage, [bl, tr]);
    overlay.addTo(this.map);
    for (let regionKey in this.siteplanData.regions) {
      const region = this.siteplanData.regions[regionKey];
      const layer = ((region: SiteplanRegionData) => {
        if (Array.isArray(region.points[0])) {
          return this.leaflet.L.polygon(
            (region.points as Array<Array<RegionPoints>>).map(elem =>
              elem.map(elem => [elem.lat, elem.lng])
            )
          );
        } else {
          return this.leaflet.L.polygon(
            (region.points as Array<RegionPoints>).map(elem => [elem.lat, elem.lng])
          );
        }
      })(region);
      layer.setStyle({ stroke: true, color: region.color });
      layer.on('click', this.onRegionLayerClick.bind(this, regionKey));
      layer.addTo(this.map);
    }
  }

  toggleLegend(): void {
    this.legendExpanded = !this.legendExpanded;
  }

  showPopup(index: number): void {
    (this.popups.toArray()[index].nativeElement as HTMLDivElement).style.visibility = 'unset';
  }
  hidePopup(index: number): void {
    (this.popups.toArray()[index].nativeElement as HTMLDivElement).style.visibility = 'hidden';
  }

  onRegionLayerClick(regionKey: string): void {
    this.legendHighlight = regionKey as RanchSubdivisions;
    this.activeRippleAnim = true;
    this.observer.detectChanges();
    setTimeout(this.cancelRippleAnim.bind(this), 500);
  }

  getClassesForLabel(id: string): Record<string, boolean> {
    return {
      active: this.legendHighlight === id,
      'ripple-container': this.legendHighlight === id && this.activeRippleAnim
    };
  }

  cancelRippleAnim(): void {
    this.activeRippleAnim = false;
    this.observer.detectChanges();
  }
}
