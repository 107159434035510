import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  baseUrl: string = environment.API_STEM;
  private options: any;

  constructor(private http: HttpClient) {}

  submitForm(payload: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.options = { headers };
    this.getUtmParamsFromSessionStorage(payload);
    return this.http.post<any>(this.baseUrl, { 'data.json': { ...payload } }, this.options).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  private getUtmParamsFromSessionStorage(payload: any) {
    if (!!window && !!window.sessionStorage) {
      const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'ref'];

      for (const utmParam of utmParams) {
        const utmParamFromSession = sessionStorage.getItem(utmParam);
        if (!!utmParamFromSession) {
          payload[utmParam] = utmParamFromSession;
        }
      }
    }
  }

  submitFormData(payload) {
    return this.http.post<any>(this.baseUrl, payload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
