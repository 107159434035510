import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MenuInterestComponent } from '../menu-interest/menu-interest.component';

@Component({
  selector: 'ranch-menu-modal',
  templateUrl: './menu-modal.component.html',
  styleUrls: ['./menu-modal.component.scss']
})
export class MenuModalComponent implements OnInit {
  showAbout = false;
  showResources = false;
  showHome = false;
  showForm = false;

  constructor(private dialogRef: MatDialogRef<MenuModalComponent>, private router: Router, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.listenForNavChange();
  }

  close(): void {
    this.dialogRef.close();
  }

  listenForNavChange(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.close();
      }
    });
  }

  openModal(): void {
    const modalConfig = new MatDialogConfig();
    modalConfig.closeOnNavigation = true;
    modalConfig.disableClose = false;
    modalConfig.backdropClass = 'backdrop-light';
    modalConfig.panelClass = 'full-screen';
    // modalConfig.scrollStrategy = 'RepositionScrollStrategy';
    // this.dialogRef.close();
    this.dialog.open(MenuInterestComponent, modalConfig);
  }

  toggleDropdown(type): void {
    if (type === 'about') {
      this.showAbout = !this.showAbout;
      const myHandler = () => {
        setTimeout(() => {
          this.showAbout = false;
          document.body.removeEventListener('click', myHandler, true);
        }, 50);
      };
      if (this.showAbout === true) {
        document.body.addEventListener('click', myHandler, true);
      }
    } else if (type === 'findHome') {
      this.showHome = !this.showHome;
      const myHandler = () => {
        setTimeout(() => {
          this.showHome = false;
          document.body.removeEventListener('click', myHandler, true);
        }, 50);
      };
      if (this.showHome === true) {
        document.body.addEventListener('click', myHandler, true);
      }
    } else {
      this.showResources = !this.showResources;
      const myHandler = () => {
        setTimeout(() => {
          this.showResources = false;
          document.body.removeEventListener('click', myHandler, true);
        }, 50);
      };
      if (this.showResources === true) {
        document.body.addEventListener('click', myHandler, true);
      }
    }
  }
}
