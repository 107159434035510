import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { PlatformService } from 'src/app/services/platform.service';
import { NavigationComponent } from '../../navigation.component';

@Component({
  selector: 'ranch-menu-sidenav',
  templateUrl: './menu-sidenav.component.html',
  styleUrls: ['./menu-sidenav.component.scss']
})
export class MenuSidenavComponent implements OnInit {
  @Input() open: boolean;
  opened: boolean;

  @ViewChild('sidenav') public sidenav: MatSidenav;
  @ViewChild(NavigationComponent) nav;

  constructor(private platform: PlatformService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.opened = this.nav.opened;
  }

  ngOnChanges(changes): void {
    if (changes.opened) {
      this.sidenav.toggle();
    }
  }

  lockScroll(type): void {
    if (!this.platform.isBrowser ()) {
      return;
    }
    const el = document.getElementsByTagName('html')[0];
    let dist;
    if (type === 'open') {

      dist = window.scrollY;
      el.style.cssText = 'left: 0px; top: -' + dist + 'px;';
      el.classList.add('cdk-global-scrollblock');
    } else {
      dist = Math.abs(el.offsetTop);
      el.classList.remove('cdk-global-scrollblock');
      el.scrollTo(0, dist);
      el.removeAttribute('style');
    }
  }
}
