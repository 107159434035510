export const TeamData = [
    {
        url: '/assets/images/Cliffe_Killam.jpg',
        caption1: 'Cliffe Killam',
        caption2: 'Partner'
      },
      {
        url: '/assets/images/Andy_K.jpg',
        caption1: 'Andy Konovodof',
        caption2: 'Division President'
      },
      {
        url: '/assets/images/Roland_O.jpg',
        caption1: 'Rolando Ortiz',
        caption2: 'Real Estate Manager'
      }
];
