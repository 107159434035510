import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { GalleryTab } from 'src/app/data/gallery.data';

@Component({
  selector: 'ranch-tab-nav',
  templateUrl: './tab-nav.component.html',
  styleUrls: ['./tab-nav.component.scss']
})
export class RanchGalleryTabNavComponent implements OnInit {
  @Input() tabs: Array<GalleryTab> = [];
  @Input() selected: number = 0;
  @Output() onSetTab: EventEmitter<number> = new EventEmitter<number>();

  constructor(private observer: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tabs']) {
      this.selected = changes['tabs']?.currentValue?.[0]?.number;
    }
  }

  setTab(tabNumber: number): void {
    this.onSetTab.emit(tabNumber);
  }
}
