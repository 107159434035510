import { AfterViewInit, Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'ranch-logo-animated',
  templateUrl: './logo-animated.component.html',
  styleUrls: ['./logo-animated.component.scss']
})
export class LogoAnimatedComponent implements OnInit, AfterViewInit {
  @Input() customClass: string;
  @ViewChild('logoSection', { static: false }) elemRef: ElementRef;
  runAnimations = true;
  constructor(private platform: PlatformService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.detectItemsInView();
  }

  detectItemsInView(): void {
    if (!this.platform.isBrowser()) {
      return;
    }
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
          this.startAnimations();
        }
      }
    });
    observer.observe(this.elemRef.nativeElement);
  }

  startAnimations(): void {
    if (this.runAnimations) {
      this.runAnimations = false;
      const baseEl = document.getElementById('animate-base-layer');
      const logoEl = document.getElementById('fade-win-logo');
      const subheadEl = document.getElementById('fade-win-subhead');
      const headEl = document.getElementById('fade-win-head');
      baseEl.classList.add('fade');
      baseEl.classList.remove('delay');
      setTimeout(() => {
        baseEl.classList.remove('fade');
        baseEl.classList.add('grow');
      }, 1000);
      setTimeout(() => {
        baseEl.classList.remove('grow');
        baseEl.classList.add('shrink');
      }, 1500);
      setTimeout(() => {
        baseEl.classList.remove('shrink');
        logoEl.classList.remove('delay');
        logoEl.classList.add('fade');
        subheadEl.classList.remove('delay');
        subheadEl.classList.add('fade');
        headEl.classList.remove('delay');
        headEl.classList.add('fade');
      }, 2000);
      setTimeout(() => {
        headEl.classList.remove('fade');
        headEl.classList.add('grow');
      }, 2500);
      setTimeout(() => {
        headEl.classList.remove('grow');
        headEl.classList.add('shrink');
      }, 3000);
      setTimeout(() => {
        headEl.classList.remove('shrink');
      }, 4000);
    }
  }
}
