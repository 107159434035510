import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ranch-community-card-horizontal',
  templateUrl: './community-card-horizontal.component.html',
  styleUrls: ['./community-card-horizontal.component.scss']
})
export class CommunityCardHorizontalComponent implements OnInit, OnDestroy {
  @Input() communityData: any;
  @Input() qmiData: any;
  @Input() isQmi: boolean;
  @Output() viewDetails: EventEmitter<number> = new EventEmitter(null);
  detailsComingSoon: boolean;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.detailsComingSoon = true;
    // this.checkForDetailsComingSoon();
    if (this.isQmi && !this.qmiData) {
      this.qmiData = { ...this.communityData };
    }
  }
  ngOnDestroy(): void {
    this.qmiData = {};
  }
  navToCommunity(id: string): void {
    this.router.navigate(['/community', id], { state: { data: id } });
  }
  navToQMI(id: number): void {
    this.viewDetails.emit(id);
    // this.router.navigate(['/quick-move-in-home', id], { state: { data: id } });
  }
  checkForDetailsComingSoon(): void {
    if (this.communityData) {
      this.detailsComingSoon = this.communityData.detailsComingSoon;
    }
  }
}
