<div id="interest-form-id" class="contain-interest-form"
  [class.contain-interest-form__interest-modal]="forFeature === 'interest-modal'">
  <div class="close-drawer" *ngIf="type === 'form' || type === 'thanks'">
    <img (click)="close()" src="../../../../assets/icons/IconClose_Color.svg" />
  </div>
  <mat-dialog-content class="modal-form">
    <div class="top-nav-bar">
      <div routerLink="/" class="logo" (click)="close()">
        <img src="/assets/logos/TheRanch-Logo_White.svg" />
      </div>
      <img (click)="close()" class="mobile-icon close" src="/assets/icons/IconClose_White.svg" />
    </div>
    <ng-container *ngIf="type === 'menu'">
      <div class="mobile-full-menu">
        <div class="mobile-nav-item" routerLink="/community">
          <div class="link" (click)="close()">
            Community
            <img src="/assets/icons/CarouselArrow-Right_Dark.svg" />
          </div>
        </div>
        <div class="mobile-nav-item" routerLink="/find-a-home">
          <div class="link" (click)="close()">
            Find a Home
            <img src="/assets/icons/CarouselArrow-Right_Dark.svg" />
          </div>
        </div>
        <div class="mobile-nav-item" routerLink="/gallery">
          <div class="link" (click)="close()">
            Gallery
            <img src="/assets/icons/CarouselArrow-Right_Dark.svg" />
          </div>
        </div>
        <div class="mobile-nav-item" routerLink="/about-overview">
          <div class="link" (click)="close()">
            About K. Hovnanian
            <img src="/assets/icons/CarouselArrow-Right_Dark.svg" />
          </div>
        </div>
        <div class="contain-btn">
          <span (click)="type = 'form'" class="interest-btn">Request Info</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 'form'">
      <div class="form-copy-container">
        <div class="close-btn" (click)="close()"><img src="/assets/icons/IconClose_Color.svg"></div>
        <div class="heading">Request Info</div>
      </div>
      <form class="com-form" [formGroup]="interestForm">
        <div class="contain-required">
          <div class="required">*Required Fields</div>
        </div>
        <div class="form-row">
          <div class="input-item">
            <label class="label" for="fname">First Name<span class="asterisk">*</span></label>
            <input class="input" type="text" id="fname" formControlName="fname" />
            <ranch-error-content [show]="showErrors(interestForm.get('fname'))"
              [fieldErrors]="interestForm.get('fname').errors" errorMappingKey="requiredOnly">
            </ranch-error-content>
          </div>
          <div class="input-item">
            <label class="label" for="lname">Last Name<span class="asterisk">*</span></label>
            <input class="input" type="text" id="lname" formControlName="lname" />
            <ranch-error-content [show]="showErrors(interestForm.get('lname'))"
              [fieldErrors]="interestForm.get('lname').errors" errorMappingKey="requiredOnly">
            </ranch-error-content>
          </div>
        </div>
        <div class="form-row">
          <div class="input-item">
            <label class="label" for="email">Email<span class="asterisk">*</span></label>
            <input class="input" type="email" id="email" formControlName="email" />
            <ranch-error-content [show]="showErrors(interestForm.get('email'))"
              [fieldErrors]="interestForm.get('email').errors" errorMappingKey="email">
            </ranch-error-content>
          </div>
          <div class="input-item">
            <label class="label" for="phone">Phone</label>
            <input class="input" type="text" id="phone" formControlName="phone" />
            <div class="consent">
              <div class="col-left">
                <mat-checkbox id="consent" name="consent" class="checkbox" formControlName="consent"></mat-checkbox>
              </div>
              <div class="col-right">
                <label for="consent">By checking this box, you agree to receive text messages.</label>
              </div>
            </div>
            <ranch-error-content [show]="showErrors(interestForm.get('phone'))"
              [fieldErrors]="interestForm.get('phone').errors" errorMappingKey="phoneNotRequired">
            </ranch-error-content>
          </div>
        </div>
        <div class="form-row">
          <div class="input-item community-checkboxes" formGroupName="interests">
            <p class="select-community">
              Select all communities of interest<span class="asterisk">*</span>
            </p>
            <div class="input-item">
              <mat-checkbox class="checkbox" formControlName="sagebrush"></mat-checkbox>
              <label class="label" for="sagebrush"><b>Sagebrush at The Ranch:</b> Single Family Homes up to 2,014 sq.
                ft.</label>
            </div>
            <!--<div class="input-item">
              <mat-checkbox class="checkbox" formControlName="springs"></mat-checkbox>
              <label class="label" for="springs"><b>Springs at The Ranch:</b> Single Family Homes up to 2,152 sq.
                ft.</label>
            </div>-->
            <div class="input-item">
              <mat-checkbox class="checkbox" formControlName="montrose"></mat-checkbox>
              <label class="label" for="montrose"><b>Montrose at The Ranch:</b> Single Family Homes up to 2,628 sq.
                ft.</label>
            </div>
            <div class="input-item">
              <mat-checkbox class="checkbox" formControlName="canyon"></mat-checkbox>
              <label class="label" for="montrose"><b>Canyon at The Ranch:</b> Single Family Homes up to 2,152 sq.
                ft.</label>
            </div>
            <div class="input-item">
              <mat-checkbox class="checkbox" formControlName="paseo"></mat-checkbox>
              <label class="label" for="montrose"><b>Paseo at The Ranch:</b> Single Family Homes up to 2,028 sq.
                ft.</label>
            </div>
            <ranch-error-content [show]="showErrors(interestForm.get('interests'))"
              [fieldErrors]="interestForm.get('interests').errors" errorMappingKey="checkboxesRequired">
            </ranch-error-content>
          </div>
        </div>
        <div class="form-row">
          <div class="input-item comments-textarea">
            <label class="label" for="comments">Comments</label>
            <textarea class="input text-area" type="text" id="comments" formControlName="comments"></textarea>
          </div>
        </div>
      </form>
      <button (click)="submit()" class="submit-btn" [disabled]="!interestForm.valid">
        Request Info
      </button>
    </ng-container>
    <ng-container *ngIf="type === 'thanks'">
      <div class="form-copy-container thank-you">
        <div class="heading">You're On the List</div>
        <p class="copy">
          Thank you for your interest in The Ranch. We have received your personal details and will
          be sending you additional information about this upcoming Rancho Cordova community as it
          becomes available.
        </p>
        <button (click)="this.type = 'form'" class="submit-btn">Request Info</button>
      </div>
    </ng-container>
  </mat-dialog-content>
</div>