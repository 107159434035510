import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';

@Component({
  selector: 'ranch-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends ComponentBase implements OnInit {
  constructor(
    private screenService: ScreenSizeService,
    private navigationService: NavigationService
  ) {
    super(screenService, navigationService);
  }

  ngOnInit(): void {}
}
