import { DOCUMENT, Location, ViewportScroller } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PlatformService } from './platform.service';

const getOffsetTop = element => {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
};
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private prevUrl: string;
  private currentUrl: string;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private location: Location,
    private platform: PlatformService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.prevUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  getPreviousUrl(): string {
    return this.prevUrl;
  }

  public navigateTo(link: string): void {
    if (!this.platform.isBrowser()) {
      return;
    }
    if (!link) return;
    /**
     * Anchors. Account for header if possible.
     */
    if (link.startsWith('#')) {
      const element = document.querySelector(link) as HTMLElement;
      const elementVerticalPosition = getOffsetTop(element);
      const headerHeight = (document.querySelector('.navigation-container') as HTMLElement)
        ?.offsetHeight;
      if (elementVerticalPosition != null && headerHeight != null) {
        // this.viewportScroller.scrollToPosition([0, elementVerticalPosition - headerHeight]);
        const wind = this.document.defaultView;
        wind.scrollTo({
          left: 0,
          top: elementVerticalPosition - headerHeight,
          behavior: 'smooth'
        });
      } else {
        this.viewportScroller.scrollToAnchor(link.replace(/^#/, ''));
      }
      /**
       * Internal relative URL
       */
    } else if (link.endsWith('.pdf')) {
      //TODO: any other downloadable file types?
      const contrivedClickable = document.createElement('a');
      contrivedClickable.href = link;
      contrivedClickable.target = '_blank'; // new tab
      contrivedClickable.click();
      contrivedClickable.remove();
    } else if (!link.startsWith('http')) {
      this.router.navigateByUrl(link);
      /**
       * External URLs
       */
    } else {
      window.open(link, '_blank');
    }
  }

  public goBack(): void {
    this.location.back();
  }
}
