import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { MenuInterestComponent } from './presentational/menu-interest/menu-interest.component';
import { MenuModalComponent } from './presentational/menu-modal/menu-modal.component';

@Component({
  selector: 'ranch-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationComponent implements OnInit {
  showMobile = false;
  opened = false;
  @ViewChild(MenuInterestComponent, { static: false }) showForm: MenuInterestComponent;

  @Output() openToggleChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private router: Router, private dialog: MatDialog) {
    this.listenForRouteActivation();
  }

  ngOnInit(): void {}

  listenForRouteActivation(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showMobile = false;
      }
    });
  }

  openSidenav(com): void {
    this.openToggleChange.emit(com);
  }

  openModal(): void {
    const modalConfig = new MatDialogConfig();
    modalConfig.closeOnNavigation = true;
    modalConfig.disableClose = false;
    modalConfig.backdropClass = 'backdrop-light';
    modalConfig.panelClass = 'full-screen';
    this.dialog.open(MenuModalComponent, modalConfig);
  }
}
