<div class="home-section-areas">
    <div class="container">
        <div class="slider">
            <div class="slide">
                <div class="contain-icon-slide">
                    <ranch-home-slider [slideConfig]="slideConfig" [customClass]="customClass" [slideData]="slideData"></ranch-home-slider>
                </div>
            </div>
        </div>
    </div>
</div>