<div #calcSection id="contain-calc" class="contain-calculator modal-calc">
    <div id="fade-calc-head-0" class="head delay">
        <div class="eaves">Mortgage</div>
        <div class="salsa">Calculator</div>
    </div>
    <div id="calculator" class="calc delay modal-calc">
        <!-- Mortgage Calculator Widget -->
        <div class="mcw-wrap_250" id="maoWrap">
            <div id="mcwWidget"></div>
            <div class="btn-wrap">
                <button (click)="openDefsModal()" class="outline-red">Definitions</button>
                <p class="disclaimer">This calculator is provided for illustrative purposes only. It is not an offer and accuracy is not guaranteed. The values and figures shown are hypothetical and may not be applicable to your individual situation; please see a Winfield Communities Sales Manager for more details.</p>
            </div>
        </div>
        <!-- End Mortgage Calculator Widget -->
    </div>
</div>