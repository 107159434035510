import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ranch-section-areas',
  templateUrl: './section-areas.component.html',
  styleUrls: ['./section-areas.component.scss']
})
export class SectionAreasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
