import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ranch-nav-spacer',
  templateUrl: './nav-spacer.component.html',
  styleUrls: ['./nav-spacer.component.scss']
})
export class NavSpacerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
