<div class="contain-menu">
  <div class="menu-content">
    <div class="top-nav-bar">
      <div routerLink="/" class="logo">
        <img src="/assets/logos/TheRanch-Logo_White.svg" />
      </div>
      <img (click)="close()" class="mobile-icon close" src="/assets/icons/IconClose_White.svg" />
    </div>
    <ng-container *ngIf="!showForm">
      <div class="mobile-full-menu">
        <div class="mobile-nav-item" routerLink="/community">
          <div class="link">
            Community
            <img src="/assets/icons/CarouselArrow-Right_Dark.svg" />
          </div>
        </div>
        <div class="mobile-nav-item" routerLink="/find-a-home">
          <div class="link">
            Find a Home
            <img src="/assets/icons/CarouselArrow-Right_Dark.svg" />
          </div>
        </div>
        <div class="mobile-nav-item" routerLink="/gallery">
          <div class="link">
            Gallery
            <img src="/assets/icons/CarouselArrow-Right_Dark.svg" />
          </div>
        </div>
        <div class="mobile-nav-item" routerLink="/about-overview">
          <div class="link">
            About K. Hovnanian
            <img src="/assets/icons/CarouselArrow-Right_Dark.svg" />
          </div>
        </div>
        <div class="contain-btn">
          <span (click)="showForm = true" class="interest-btn">Request Info</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showForm">
      <ranch-menu-interest></ranch-menu-interest>
    </ng-container>
  </div>
</div>