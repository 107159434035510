import { BehaviorSubject } from 'rxjs';
import { Injectable, ViewContainerRef, TemplateRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal, ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ScreenSizeService } from './../../services/screen-size.service';
import { take } from 'rxjs/operators';

const DEFAULT_MODAL_CONFIG = new OverlayConfig({
  hasBackdrop: true,
  disposeOnNavigation: true,
  panelClass: ['modal', 'is-active'],
  backdropClass: 'modal-background'
});

const DRAWER_RIGHT_CONFIG = new OverlayConfig({
  hasBackdrop: true,
  panelClass: ['modal', 'modal--drawer', 'modal--drawer-right', 'is-active'],
  backdropClass: 'modal-background'
});

export const MODAL_CONFIG = {
  dialog: DEFAULT_MODAL_CONFIG,
  drawerRight: DRAWER_RIGHT_CONFIG
};

@Injectable({ providedIn: 'root' })
export class ModalService {
  private currentModalRef: OverlayRef;
  isModalOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private overlay: Overlay, private screenSizeService: ScreenSizeService) {}

  public open(
    content: TemplateRef<any> | ComponentType<unknown>,
    viewContainerRef: ViewContainerRef,
    config: OverlayConfig = MODAL_CONFIG.dialog,
    skipBackdropHandling: boolean = false
  ): OverlayRef {
    this.currentModalRef = this.overlay.create(config);
    const portal = this.getPortal(content, viewContainerRef);
    this.currentModalRef.attach(portal);
    this.isModalOpen$.next(true);
    if (!skipBackdropHandling) {
      this.currentModalRef.backdropClick().subscribe(() => {
        this.close();
      });
    }
    return this.currentModalRef;
  }

  public openNoBackdropClick(
    content: TemplateRef<any> | ComponentType<unknown>,
    viewContainerRef: ViewContainerRef,
    config: OverlayConfig = MODAL_CONFIG.dialog
  ): OverlayRef {
    return this.open(content, viewContainerRef, config, true);
  }

  public closeOnMobile(): void {
    this.screenSizeService
      .isMobile$
      .pipe(take(1))
      .subscribe((isMobileView: boolean) => {
        if (isMobileView) {
          this.close();
        }
      });
  }

  public close(): void {
    this.isModalOpen$.next(false);

    if (!this.currentModalRef) {
      return;
    } else {
      this.currentModalRef.dispose();
      this.currentModalRef = null;
    }
  }

  private getPortal(
    content: TemplateRef<any> | ComponentType<unknown>,
    viewContainerRef: ViewContainerRef
  ): TemplatePortal | ComponentPortal<unknown> {
    if (content instanceof TemplateRef) {
      return new TemplatePortal(content, viewContainerRef);
    }

    return new ComponentPortal(content, viewContainerRef);
  }
}

/**
 * Example of how to use this modal service
 *
 * //  Template
 *
 <ng-template #tpl>
   <utour-modal-template header="Modal header">
     // Body of modal goes below
     <div class="modal-body">
       <h1>Yo I'm body</h1>
       <p>hello workd</p>
     </div>
   </utour-modal-template>
 </ng-template>
 // Trigger modal open
 <button class="ut-button" (click)="openWithTemplate()">Open modal</button>

 // Component Class
 constructor(private viewContainerRef: ViewContainerRef, private modalService: ModalService) {}

 openWithTemplate(tpl: TemplateRef<any>): void {
   this.modalService.open(tpl, this.viewContainerRef);
 }

 openWithComponent(): void {
   this.modalService.open(ModalTemplateComponent, this.viewContainerRef);
 }

 subscribeToCloseEvent(): void {
  const modalRef = this.modalService.open(ModalTemplateComponent, this.viewContainerRef);

  modalRef.detachments().subscribe(() => {
    console.log('called after modal has been detached');
  });
 }
 */
