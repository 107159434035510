import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Input,
  Renderer2,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output
} from '@angular/core';
import { ScrollService } from '../../services/scroll.service';
import { Subscription } from 'rxjs';
import { PlatformService } from 'src/app/services/platform.service';

declare var maoCalc: any;
@Component({
  selector: 'ranch-mortgage-calc',
  templateUrl: './mortgage-calc.component.html',
  styleUrls: ['./mortgage-calc.component.scss']
})
export class MortgageCalcComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('calcSection', { static: false }) elemRef: ElementRef;
  @Input() price: string;
  @Output() openDefs: EventEmitter<any> = new EventEmitter();
  sub: Subscription;
  downpayment: any;
  constructor(
    private scrollService: ScrollService,
    private readonly elementRef: ElementRef,
    private platform: PlatformService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {}
  openDefsModal(): void {
    this.openDefs.emit('anything');
  }
  ngAfterViewInit(): void {
    this.listenForScroll();
    this.detectItemsInView();
    this.loadScript();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.price = changes?.price?.currentValue.replace('$', '');
    this.downpayment = Intl.NumberFormat('en-US').format(
      Math.round((parseInt(this.price.replace(/,/g, ''), 10) * 0.035 + Number.EPSILON) * 100) / 100
    );
    if (this.price === "350's to $370's") {
      maoCalc.settings.homePrice.value = '365,000';
      maoCalc.settings.downPayment.value = '12,424.97';
    } else {
      maoCalc.settings.homePrice.value = this.price;
      maoCalc.settings.downPayment.value = this.downpayment;
    }
  }

  ngOnDestroy(): void {
    this.removeElems();
    this.sub.unsubscribe();
  }

  decode(str: any): string {
    return !window.btoa ? 'not_found' : window.btoa(unescape(encodeURIComponent(str)));
  }

  loadScript(): void {
    const script = this.renderer.createElement('script');
    script.src =
      'https://mortgage-advice-online.org/widget/' + this.decode(window.location) + '.js';
    this.renderer.appendChild(this.elementRef.nativeElement, script);
  }

  detectItemsInView(): void {
    if (!this.platform.isBrowser()) {
      return;
    }
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
          this.addFade('fade-calc-head-0');
          this.addFade('calculator');
        }
      }
    });
    observer.observe(this.elemRef.nativeElement);
  }

  addFade(id: any): void {
    const el = document.getElementById(id);
    if (el) {
      if (id.includes('0')) {
        el.classList.add('fade');
      } else {
        el.classList.add('fade-0');
      }
    }
  }

  listenForScroll(): void {
    this.sub = this.scrollService.anchorScroll$.subscribe(res => {
      if (res) {
        const calcEl = document.getElementById('contain-calc');
        const offset = 70;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elRect = calcEl.getBoundingClientRect().top;
        const elPos = elRect - bodyRect;
        const anchorPos = elPos - offset;
        window.scrollTo({
          top: anchorPos,
          behavior: 'smooth'
        });
        this.scrollService.anchorScroll$.next(false);
      }
    });
  }

  removeElems(): void {
    const selectElArr = Array.from(document.getElementsByClassName('select-theme-default'));
    for (const item of selectElArr) {
      item.parentNode.removeChild(item);
    }
  }
}
