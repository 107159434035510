import { ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { RanchAddress, RanchLocation } from 'src/app/data/community-data';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { AddressData, AreaAmenityCategory, AreaAmenityEntry, AreaAmenityMapConfig } from 'src/app/types/community.types';
import { ComponentBase } from 'src/app/utils/base-conmponent.class';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'ranch-community-area-map',
    templateUrl: './area-map.component.html',
    styleUrls: ['./area-map.component.scss']
})
export class RanchCommunityAreaMapComponent extends ComponentBase implements OnInit {

    @Input() areaAmenities: Array<AreaAmenityCategory> = [];
    @Input() mapConfig: AreaAmenityMapConfig;

    currentCategory: number = -1;
    deactivatedItems: Array<number> = [];
    ranchPosition = RanchLocation;

    subIcon = `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Plus-Minus/Minus.svg`;
    plusIcon = `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Plus-Minus/Plus.svg`;
    checkboxSelected = `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Checkbox/Checkbox_Selected.svg`;
    checkboxUnselected = `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Checkbox/Checkbox_Unselected.svg`
    ranchPinIcon = `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Area/Pins/Community-Pin.svg`
    ranchLogo = `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Logos/TheRanch-Logo_Charcoal.svg`
    ranchAddress: AddressData & { telephone: string } = RanchAddress;
    @ViewChild('ranchMarker', { read: MapMarker }) ranchMarker: MapMarker;
    @ViewChild('ranchInfoWindow', { read: MapInfoWindow }) ranchInfoWindow: MapInfoWindow;
    @ViewChildren('markers', { read: MapMarker }) markers: QueryList<MapMarker>;
    @ViewChildren('infoWindows', { read: MapInfoWindow }) infoWindows: QueryList<MapInfoWindow>;

    constructor(
        private screenService: ScreenSizeService,
        private navigationService: NavigationService,
        private observer: ChangeDetectorRef
    ) {
        super(screenService, navigationService);
    }

    ngOnInit(): void {
    }

    selectCategory(idx: number): void {
        if (this.currentCategory === idx) {
            this.currentCategory = -1;
        } else {
            this.currentCategory = idx;
        }
        this.deactivatedItems = [];
        this.observer.detectChanges();
    }
    isItemActive(idx: number): boolean {
        return !this.deactivatedItems.includes(idx);
    }
    toggleItemActive(idx: number): void {
        if (this.deactivatedItems.includes(idx)) {
            this.deactivatedItems.splice(this.deactivatedItems.indexOf(idx));
        } else {
            this.deactivatedItems.push(idx);
        }
    }
    getActiveItems(): Array<AreaAmenityEntry> {
        return (this.areaAmenities[this.currentCategory]?.items
            .filter((_, i) => !this.deactivatedItems.includes(i))) ?? [];
    }
    getCurrentCategoryIcon(): string {
        return this.areaAmenities[this.currentCategory]?.iconPin ?? '';
    }
    closeAllWindows(): void {
        this.ranchInfoWindow?.close();
        this.infoWindows.forEach(item => item.close());
    }
    openRanchInfoWindow(): void {
        this.closeAllWindows();
        this.ranchInfoWindow.open(this.ranchMarker);
    }
    closeRanchInfoWindow(): void {
        this.ranchInfoWindow.close();
    }
    openInfoWindow(idx: number): void {
        this.closeAllWindows();
        this.infoWindows.toArray()[idx]?.open(this.markers.toArray()[idx]);
    }
    closeInfoWindow(idx: number): void {
        this.infoWindows.toArray()[idx]?.close();
    }
}
