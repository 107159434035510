import { Optional } from '@angular/core';
import { environment } from 'src/environments/environment';

import { NavigationService } from '../services/navigation.service';
import { ScreenSizeService } from '../services/screen-size.service';
import { BreakpointsUtils } from './breakpoints-base-class.utils';

/**
 * Do any desired component extending here.
 */

export class ComponentBase extends BreakpointsUtils {
  constructor(
    @Optional() private _screenService: ScreenSizeService,
    @Optional() private _navigationService: NavigationService
  ) {
    super(_screenService);
  }

  public navigateTo(link: string) {
    this._navigationService.navigateTo(link);
  }

  public navigateToExternal(link: string) {
    window.open(link, '_blank').focus();
  }

  public S3_URL: string = environment.S3_URL;
}
