import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ranch-calc-definitions',
  templateUrl: './calc-definitions.component.html',
  styleUrls: ['./calc-definitions.component.scss']
})
export class CalcDefinitionsComponent implements OnInit {
  definitions = [
    {
      term: 'Down Payment',
      definition: `The amount of your home’s purchase price that you pay up-front when you close on your home loan.
      Down payment is expressed as both a percentage of the home’s purchase price, and as a dollar amount. Most loan types
      (i.e., FHA and Conventional Loans) require a minimum percentage of the home’s purchase price to be paid at closing. For example, for an FHA loan,
      3.5% of the purchase price must be paid up-front as the down payment.`
    },
    {
      term: 'Interest Rate',
      definition: `The amount of a mortgage’s principal loan balance that the lender charges the borrower for borrowing the money to purchase a new home. Interest rates vary by lender and mortgage type. They change frequently based upon market conditions and the overall economic outlook. Please consult with your lender for the most accurate terms and rates.`
    },
    {
      term: 'Mortgage Term',
      definition: `How long you have to repay your mortgage. For most types of homes, mortgage terms are typically 15 or 30 years.`
    },
    {
      term: 'Property Taxes',
      definition: `The tax paid on property owned by an individual, based upon the assessed value of real property and tax rates. Property taxes vary by location, home value, land value and other factors.`
    },

    {
      term: 'Homeowners Insurance',
      definition: `A form of property insurance that covers losses and damages to an individual’s residence, along with furnishings and other assets in the home. Homeowners insurance varies, and oftentimes has liability limits, meaning specific conditions related to what sorts of accidents or damages are covered, and the associated monetary terms.`
    }
  ];
  @Output() backCalc: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  goBack(): void {
    this.backCalc.emit();
  }
}
