import { Component, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ScreenSizeService } from '../../../../services/screen-size.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'ranch-history-section',
  templateUrl: './history-section.component.html',
  styleUrls: ['./history-section.component.scss']
})
export class HistorySectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('historySection', { static: false }) elemRef: ElementRef;
  isDesktop: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private screenService: ScreenSizeService, private platform: PlatformService) {
    this.setScreen();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.detectItemsInView();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setScreen(): void {
    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.isDesktop = res;
    });
  }

  detectItemsInView(): void {
    if (!this.platform.isBrowser()) {
      return;
    }
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
          this.addFade('fade-history-head-all-0');
          this.addFade('fade-history-text-1');
          this.addFade('fade-killam-btn-2');
        }
      }
    });
    observer.observe(this.elemRef.nativeElement);
  }

  addFade(id): void {
    const el = document.getElementById(id);
    if (el) {
      if (id.includes('0')) {
        el.classList.add('fade');
      } else if (id.includes('1')) {
        el.classList.add('fade-0');
      } else if (id.includes('2')) {
        el.classList.add('fade-1');
      }
    }
  }
}
