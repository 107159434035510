import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { NavigationService } from 'src/app/services/navigation.service';
import { HeroConfig } from 'src/app/types/hero.types';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';
import { ScreenSizeService } from '../../services/screen-size.service';

@Component({
  selector: 'ranch-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent extends ComponentBase implements OnInit, OnDestroy {
  @Input() hero: HeroConfig;
  hideHead = true;
  showAndAnimate = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private screenService: ScreenSizeService,
    private navigationService: NavigationService,
    private domSanitizer: DomSanitizer
  ) {
    super(screenService, navigationService);
  }

  ngOnInit(): void {
    this.addAnimations();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addAnimations(): void {
    this.hideHead = false;
    this.showAndAnimate = true;
  }

  findClass(): string {
    if (this.hero.heroClass) {
      return this.hero.heroClass;
    }
  }

  sanitizeHTML(html): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}
