<div class="slider-home">
    <div class="content-container">
        <div id="slider-id" class="swiper-container" [swiper]="slideConfig" (indexChange)="onIndexChange($event)">
            <div #swiperComp class="swiper-wrapper">
                <div *ngFor="let slide of slideData" class="swiper-slide">
                    <div class="contain-slide">
                        <div class="col-left">
                            <img [src]="slide?.url">
                        </div>
                        <div class="col-right">
                            <div class="partial-background"></div>
                            <div class="caption">
                                <h1 class="heading caption-1">{{ slide?.caption1 }}</h1>
                                <p class="copy caption-2">{{ slide?.caption2 }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="scale-contain">
                    <div class="col-left">
                        <img src="/assets/images/bg_scaler_home.png">
                        <div *ngIf="slideConfig.a11y && slideData.length > 1" class="swiper-button-prev"></div>
                        <div *ngIf="slideConfig.a11y && slideData.length > 1" class="swiper-button-next"></div>
                        <div class="pagination"></div>
                    </div>
                    <div class="col-right"></div>
                </div>
            </div>
        </div>
    </div>
</div>