import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { HeroConfig } from 'src/app/types/hero.types';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ranch-community-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class RanchCommunityHeaderComponent extends ComponentBase implements OnInit {
  bannerImageUrl = `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Subpage+Headers/Desktop+Subpage+Header/Community_Header_Desktop.png`;

  public hero: HeroConfig;

  constructor(
    private screenService: ScreenSizeService,
    private navigationService: NavigationService
  ) {
    super(screenService, navigationService);
  }

  ngOnInit(): void {
    this.getHeroData();
  }

  getHeroData(): void {
    this.hero = {
      heroImg: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Subpage+Headers/Desktop+Subpage+Header/Community_Header_Desktop.png`,
      heroImgMobile: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Subpage+Headers/Mobile+Subpage+Header/Amenity_Header_Mobile.png`,
      text: {
        heroText: 'Make an Escape to an<br>Exceptional New Way<br>of Living'
      },
      mobileText: {
        heroText: 'Make an Escape to an<br>Exceptional New Way<br>of Living'
      },
      heroClass: 'community-hero'
    };
  }
}
