import { BehaviorSubject } from 'rxjs';

import { ScreenSizeService } from './../services/screen-size.service';

export class BreakpointsUtils {
  public aBreakpointWasCrossed$ = new BehaviorSubject(null);

  constructor(private __screenService: ScreenSizeService) {
    this.__screenService.isMobile$.subscribe(next => {
      this.isMobile = next;
      this.aBreakpointWasCrossed$.next(Date.now());
    });
    this.__screenService.isTablet$.subscribe(next => {
      this.isTablet = next;
      this.aBreakpointWasCrossed$.next(Date.now());
    });
    this.__screenService.isDesktop$.subscribe(next => {
      this.isDesktop = next;
      this.aBreakpointWasCrossed$.next(Date.now());
    });
    this.__screenService.isMobileLandscape$.subscribe(next => {
      this.isMobileLandscape = next;
      this.aBreakpointWasCrossed$.next(Date.now());
    });
    this.__screenService.isLtTablet$.subscribe(next => {
      this.isLtTablet = next;
      this.aBreakpointWasCrossed$.next(Date.now());
    });
  }

  public isMobile: boolean;
  public isMobileLandscape: boolean;
  public isTablet: boolean;
  public isLtTablet: boolean;
  public isDesktop: boolean;

  // Use for classes that are already extended 1x.
  // classInstance must have screenService injected
  public static subscribeForClass(classInstance): void {
    (classInstance.screenService as ScreenSizeService).isMobile$.subscribe(
      val => (classInstance.isMobile = val)
    );
    (classInstance.screenService as ScreenSizeService).isTablet$.subscribe(
      val => (classInstance.isTablet = val)
    );
    (classInstance.screenService as ScreenSizeService).isDesktop$.subscribe(
      val => (classInstance.isDesktop = val)
    );
    (classInstance.screenService as ScreenSizeService).isMobileLandscape$.subscribe(
      val => (classInstance.isMobileLandscape = val)
    );
    (classInstance.screenService as ScreenSizeService).isLtTablet$.subscribe(
      val => (classInstance.isLtTablet = val)
    );
  }
}
