<footer class="footer">
  <div class="container">
    <div class="column-left">
      <img class="logo-ranch" src="/assets/logos/TheRanch-Logo_Charcoal.svg" />
      <div class="contact">
        <a
          target="_blank"
          href="https://www.google.com/maps/dir//4221+Rancho+Cordova+Pkwy,+Rancho+Cordova,+CA+95742/@38.5501253,-121.2264787,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x809ae9ac41627be7:0x7ddfa5c54a9cd370!2m2!1d-121.22429!2d38.5501253!3e0"
          ><span class="address {{ isMobile ? 'mobile' : '' }}"
            >4221 Rancho Cordova Pkwy, Rancho Cordova, CA 95742</span
          ></a
        >
        <span class="separator">|</span>
        <a class="phone" href="tel:888-802-1235"
          ><span *ngIf="!isDesktop">Call </span>888.802.1235</a
        >
        <a
          class="phone directions"
          href="https://www.google.com/maps/dir//4221+Rancho+Cordova+Pkwy,+Rancho+Cordova,+CA+95742/@38.5501253,-121.2264787,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x809ae9ac41627be7:0x7ddfa5c54a9cd370!2m2!1d-121.22429!2d38.5501253!3e0"
          >GET DIRECTIONS</a
        >
      </div>
      <div class="social">
        <a href="https://www.instagram.com/khovnorcal/" target="_blank"
          ><img src="/assets/icons/Instagram_Icon_White.svg"
        /></a>
        <a href="https://www.facebook.com/khov.nocal/" target="_blank"
          ><img src="/assets/icons/FB_Icon_White.svg"
        /></a>
      </div>
      <a href="https://www.khov.com/?ref=TheRanchWebsite" target="_blank"
        ><img class="logo-khov" src="/assets/logos/Khov-Logo_White.svg"
      /></a>
      <div class="disclaim">
        K. Hovnanian at Jaeger Ranch, LLC. See a Sales Consultant for full details. Equal Housing
        Opportunity.
      </div>
      <div class="privacy">
        <a
          target="_blank"
          href="https://www.khov.com/equal-opportunity-housing/?ref=TheRanchWebsite"
          >Equal Housing Opportunity</a
        >
        <span class="separator"> | </span>
        <a target="_blank" href="https://www.khov.com/terms-conditions/?ref=TheRanchWebsite"
          >Privacy, Terms & Conditions</a
        >
      </div>
    </div>
    <div class="column-right">
      <a href="https://www.khov.com/?ref=TheRanchWebsite" target="_blank"
        ><img class="logo" src="/assets/logos/Khov-Logo_White.svg"
      /></a>
      <div class="social">
        <a href="https://www.instagram.com/khovnorcal/" target="_blank"
          ><img src="/assets/icons/Instagram_Icon_White.svg"
        /></a>
        <a href="https://www.facebook.com/khov.nocal/" target="_blank"
          ><img src="/assets/icons/FB_Icon_White.svg"
        /></a>
      </div>
    </div>
  </div>
</footer>
