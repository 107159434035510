import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ranch-gallery-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class RanchGalleryHeaderComponent implements OnInit {
  bannerImageUrl = `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Subpage+Headers/Desktop+Subpage+Header/Gallery_Header_Desktop.png`;

  @Input() taglineOnly: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
