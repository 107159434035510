<div class="home-section-amenities">
    <div class="container">
        <h1 #homeAmenities class="heading" [@hidVis]="showAmenities ? 'vis' : 'hid'">Renew Your Senses at The Ranch</h1>
        <p class="copy" [@hidVis]="showAmenities ? 'vis' : 'hid'">Amenities are abundant thanks to the pristine natural
            environment that surrounds The Ranch. There are countless opportunities for adventure as well as relaxation
            available throughout this scenic community. These are the activities that make up the fabric of life here in
            Rancho Cordova.</p>
        <div class="list" [@hidVis]="showAmenities ? 'vis' : 'hid'">
            <div class="item">
                <img class="icon"
                    [src]="S3_URL + 'images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Community/Amenities_Bike_White.svg'">
                <p class="details">Stroll along<br> community trails</p>
            </div>
            <div class="item">
                <img class="icon"
                    [src]="S3_URL + 'images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Community/Amenities_Playground_White.svg'">
                <p class="details">Let your kids enjoy the playgrounds and parks</p>
            </div>
            <div class="item">
                <img class="icon"
                    [src]="S3_URL + 'images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Community/Amenities_Soccer_White.svg'">
                <p class="details">Join a pick-up game at one of the sports fields</p>
            </div>
            <div class="item">
                <img class="icon"
                    [src]="S3_URL + 'images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Community/Amenities_Butterfly_White.svg'">
                <p class="details">Appreciate the wonders of the butterfly garden</p>
            </div>
            <div class="item">
                <img class="icon"
                    [src]="S3_URL + 'images/3_final_files/Website+Assets+Phase+2-3/Icons/Amenities+-+Community/Amenities_Trees_White.svg'">
                <p class="details">Explore the 198 acres of on-site nature preserve</p>
            </div>
            <div class="item">
                <div class="explore-amenities" (click)="navigateTo('community')">
                    <span class="explore-amenities-label">Explore Amenities</span>
                </div>
            </div>
        </div>
    </div>
</div>