import { Component, OnInit, ViewChild, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GalleryModalComponent } from '../../common/gallery-modal/gallery-modal.component';
import { ScreenSizeService } from '../../services/screen-size.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ranch-section-slider-homes',
  templateUrl: './section-slider-homes.component.html',
  styleUrls: ['./section-slider-homes.component.scss']
})
export class SectionSliderHomesComponent implements OnInit {
  @Input() slideData: any;
  @Input() slideConfig: SwiperConfigInterface;
  @Input() customClass: string;
  @ViewChild('swiperComp', { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;
  currentIndex = 0;
  isDesktop: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialog: MatDialog,
    private screenService: ScreenSizeService
  ) {
    this.detectScreenSize();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  detectScreenSize(): void {
    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.isDesktop = res;
    });
  }

  onIndexChange(evt): void {
    this.currentIndex = evt;
  }

  openModal(): void {
    const modalData = {
      slideData: this.slideData,
      index: this.currentIndex
    };
    const modalConfig = new MatDialogConfig();
    modalConfig.closeOnNavigation = true;
    modalConfig.disableClose = false;
    modalConfig.panelClass = 'full-screen';
    modalConfig.backdropClass = 'backdrop-dark';
    modalConfig.data = modalData;
    const modalRef = this.dialog.open(GalleryModalComponent, modalConfig);
  }
}