<div class="logo-text-section">
    <div class="line"></div>
    <div class="contain-logo-text">
        <div class="logo-text logo">
            <ranch-logo-animated [customClass]="customClass"></ranch-logo-animated>
        </div>
        <div class="logo-text text">
            <div #logoText id="fade-logo-text" class="text delay">
                You want more than a new home... you want a new lifestyle to go with it. A lifestyle that makes the most of a hometown-style setting and the Texas traditions of family, generosity, adventure and fun. Winfield Communities is proud to bring the best of the Lone Star State to life, because our founding family has proudly been part of Texas history for over 100 years.
            </div>
        </div>
    </div>
</div>
