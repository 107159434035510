import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ranch-community-card-vertical',
  templateUrl: './community-card-vertical.component.html',
  styleUrls: ['./community-card-vertical.component.scss']
})
export class CommunityCardVerticalComponent implements OnInit {
  @Input() communityData: any;
  @Input() qmiData: any;
  @Input() isQmi: boolean;
  @Output() viewDetails: EventEmitter<any> = new EventEmitter();
  detailsComingSoon: boolean;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.checkForDetailsComingSoon();
  }

  navToCommunity(id: string): void {
    this.router.navigate(['/community', id], { state: { data: id } });
  }
  navToQMI(id: number) {
    this.viewDetails.emit(id);
    // this.router.navigate(['/quick-move-in-home', id], { state: { data: id } });
  }
  checkForDetailsComingSoon(): void {
    if (this.communityData) {
      this.detailsComingSoon = true;
    }
  }
}
