import { SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

export enum GalleryTabs {
  Community = 'Community',
  HomeDesigns = 'Home Designs',
  WalkingTours = 'Walking Tours'
}

export interface GalleryTab {
  label: string;
  number: number;
}

export type ImageWithCaption = {
  src: string;
  caption: string;
};

export type GalleryConfig = {
  communityImages: Array<ImageWithCaption>;
  homeDesignImages: Array<ImageWithCaption>;
  tourIframeUrls: Array<string | SafeResourceUrl>;
};

export type GalleryPreviewConfig = Array<{
  url: string;
  caption1: string;
  caption2: string;
  navTarget: string;
  navLabel: string;
}>;

export const GalleryData: GalleryConfig = {
  communityImages: [
    {
      src: '/assets/gallery/PaseoattheRanch-ParkAreaRenderings-Entrance.jpg',
      caption: 'Entrance to The Ranch'
    },
    {
      src: '/assets/gallery/PaseoattheRanch-ParkAreaRenderings-BirdsEyeView.jpg',
      caption: 'Future Tree Top and Animal Tracks playground, green space and half basketball court'
    },
    {
      src: '/assets/gallery/PaseoattheRanch-ParkAreaRenderings-PicnicArea.jpg',
      caption: 'Future Neighborhood Park picnic pavilion'
    },
    {
      src: '/assets/gallery/PaseoattheRanch-ParkAreaRenderings-Playground.jpg',
      caption: 'Future Neighborhood Park playground area'
    },
    {
      src: '/assets/gallery/PaseoattheRanchParkAreaRenderings-BasketballCourt.jpg',
      caption: 'Future Neighborhood Park Half basketball court'
    },
    {
      src: '/assets/gallery/PaseoattheRanch-ParkAreaRenderings-Entrance-2.jpg',
      caption: 'Future Entrance to Neighborhood Park'
    }
  ],
  homeDesignImages: [],
  tourIframeUrls: ['https://example.com?elem=1', 'https://example.com?elem=2']
};

export const GalleryPreviewData: GalleryPreviewConfig = [
  {
    url: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+2-3/Photos/Body+Images/Homepage/Homepage_Body_Community.png`,
    caption1: 'A Getaway Without Going Far',
    caption2:
      'Preview the community amenities that set The Ranch apart from anything you’ve seen before. You’ll never have to look far for this on-site excitement.',
    navTarget: '/gallery?tab=0',
    navLabel: 'Community Gallery'
  },
  {
    url: '/assets/images/Home_Slide_Sagebrush.jpg',
    caption1: 'A Thoughtfully-Designed Vision',
    caption2:
      'See each of the remarkable homes available here in Rancho Cordova. No matter which you most prefer, you’re sure to appreciate every last detail.',
    navTarget: '/gallery?tab=1',
    navLabel: 'Home Designs Gallery'
  }
  //   {
  //     url: '/assets/images/Home_Slide_Springs.jpg',
  //     caption1: 'The First Step Into Your Future',
  //     caption2: 'Experience for yourself all there is to love here at The Ranch. These walking tours will give you the best perspective possible to explore virtually.',
  //     navTarget: '/gallery?tab=2',
  //     navLabel: 'Walking Tours Gallery'
  //   }
];
