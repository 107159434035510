import { PlatformService } from 'src/app/services/platform.service';
import { NavigationEnd } from '@angular/router';
import { EventTrackingService } from 'src/app/services/event-tracking.service';
import { Injectable } from '@angular/core';
 
@Injectable({
   providedIn: 'root'
})
export class SeoHelperService {
   constructor(
       private eventTrackingService: EventTrackingService,
       private platformService: PlatformService
   ) { }
 
   setPageData(pageTitle: string, pageDescription: string, event: NavigationEnd): void {
       // remove pixel scripts on each navigation to avoid their duplication and unnecessary precense on the pag
       // they are dynamically added by google tag manager
       if (this.platformService.isBrowser()) {
           const existingPixelScriptTags = document.querySelectorAll('#beback-pixel');
           existingPixelScriptTags.forEach(pixelScriptNode => pixelScriptNode.remove());
       }
       this.eventTrackingService.logPageViewToGA(event.urlAfterRedirects, pageTitle);
   }
}
