<div class="contain-card">
    <div class="salsa">
        {{cardData.salsaText}}
    </div>
    <div class="eaves-head">
        {{cardData.eavesHead}}
    </div>
    <div class="eaves-text">
        {{cardData.subhead}}
    </div>
</div>
