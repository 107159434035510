import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlatformService } from 'src/app/services/platform.service';
import { environment } from 'src/environments/environment';
import { ScreenSizeService } from '../../../../services/screen-size.service';

@Component({
  selector: 'ranch-about-overview-page',
  templateUrl: './about-overview-page.component.html',
  styleUrls: ['./about-overview-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutOverviewPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('aboutCards', { static: false }) elemRef: ElementRef;
  hero: any;
  cardData: any[];
  slideData: any[];
  slideConfig: SwiperConfigInterface;
  isDesktop: boolean;
  customClass = 'about';
  introHeading: string;
  introCopy: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private screenService: ScreenSizeService,
    private platform: PlatformService,
    private title: Title,
    private meta: Meta) {
    this.getScreenSize();
    this.getHeroData();
  }

  ngOnInit(): void {
    this.setMeta();
    this.setSlider();
  }

  ngAfterViewInit(): void {
    // this.detectItemsInView();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setMeta(): void {
    this.title.setTitle('About Us | The Ranch');
    this.meta.addTags([
      {
        name: 'description',
        content:
          'With both one- and two-level plans available, this collection offers unmatched variety. Select a design that matches your unique style for a living space that offers up to 2,152 square feet, five bedrooms and a loft.'
      }
    ]);
  }

  getScreenSize(): void {
    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.isDesktop = true;
      } else {
        this.isDesktop = false;
      }
      this.introHeading = 'Committed to Creating Communities of Excellence';
      this.introCopy =
        'With over 60 years of experience in 15 unique markets across the country, K. Hovnanian<sup>&reg;</sup> Homes is well-equipped to bring your dream home to life. We’ll ensure your home reflects your unique style — and is built with the utmost care and quality materials. This is why K. Hovnanian<sup>&reg;</sup> Homes is The <em>First</em> Name in Lasting <em>Value</em>.<sup>&reg;</sup>';
    });
  }

  detectItemsInView(): void {
    if (!this.platform.isBrowser()) {
      return;
    }
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
          this.addFade('about-card-0');
          this.addFade('about-card-1');
          this.addFade('about-card-2');
        }
      }
    });
    observer.observe(this.elemRef.nativeElement);
  }

  addFade(id): void {
    const el = document.getElementById(id);
    if (el) {
      if (id.includes('0')) {
        el.classList.add('fade');
      } else if (id.includes('1')) {
        el.classList.add('fade-0');
      } else if (id.includes('2')) {
        el.classList.add('fade-1');
      }
    }
  }

  getHeroData(): void {
    this.hero = {
      heroImg: '/assets/images/Hero_About.jpg',
      heroImgMobile: `${environment.S3_URL}images/3_final_files/Website+Assets+Phase+1/Photos/Mobile+Subpage+Header/About+Khov+Header+-+Mobile.png`,
      text: {
        heroText: 'Countryside Living, Curated by<br> K. Hovnanian® Homes'
      },
      mobileText: {
        heroText: 'Countryside Living, Curated by<br> K. Hovnanian® Homes'
      },
      heroClass: 'about-overview'
    };
  }

  setSlider(): void {
    this.slideConfig = {
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide'
      },
      direction: 'horizontal',
      slidesPerView: 1,
      keyboard: true,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      loop: true,
      autoHeight: true,
      centeredSlides: true,
      effect: 'fade',
      pagination: {
        el: '.pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">0' + (index + 1) + '</span>';
        }
      }
    };
    this.slideData = [
      {
        url: '/assets/images/About_Slide_Different.jpg',
        caption1: 'What Sets Us Apart',
        caption2:
          'As a family-owned builder, we understand the importance of family, and strive to build every home as we would build our own. The exacting standards we use speak for themselves within our award-winning designs and authenticity.',
        btnCopy: 'Learn More on KHOV.com',
        btnLink: 'https://www.khov.com/why-choose-k-hovnanian'
      },
      {
        url: '/assets/images/About_Slide_History.jpg',
        caption1: 'Our History of Home',
        caption2:
          'In 1959, three brothers in a New Jersey trailer started a homebuilding business. Nearly a decade later, Hovnanian Enterprises®, Inc. was founded, launching a company that is widely known today.',
        btnCopy: 'Learn More on KHOV.com',
        btnLink: 'https://www.khov.com/why-choose-k-hovnanian'
      },
      {
        url: '/assets/images/About_Slide_Success.jpg',
        caption1: 'Strengthened by Success',
        caption2:
          'It is important to know that the company building your home has a sound legacy of business success. Hovnanian Enterprises®, Inc. began trading on the New York Stock Exchange 20 years ago and ranks among the top homebuilding companies in America.',
        btnCopy: 'Learn More on KHOV.com',
        btnLink: 'https://www.khov.com/why-choose-k-hovnanian'
      }
    ];
  }
}
