<div (mouseenter)="setHover(true)" (mouseleave)="setHover(false)" class="contain-card">
    <img src="{{cardData.cardImg}}" class="card-img">
    <div *ngIf="!showHover" class="footer">
        <div class="footer-text">
            {{cardData.footerText}}
        </div>
    </div>
    <div *ngIf="showHover" class="hover">
        <div class="footer-text">
            {{cardData.footerText}}
        </div>
        <div class="hover-text">
            {{cardData.hoverText}}
        </div>
    </div>
</div>
