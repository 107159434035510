import { GalleryTabs } from './../data/gallery.data';

export const createTabs = data => {
  const newTabs = [];
  // These numbers must stay the same
  if (data.communityImages) {
    newTabs.push({ label: GalleryTabs.Community, number: 0 });
  }
  if (data.homeDesignImages) {
    newTabs.push({ label: GalleryTabs.HomeDesigns, number: 1 });
  }
  if (data.tourIframeUrls) {
    newTabs.push({ label: GalleryTabs.WalkingTours, number: 2 });
  }
  return newTabs;
};
