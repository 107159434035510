import { DropdownItemValue } from '../types/interfaces';

export const getDataLayer = (
    eventCategory: string,
    eventAction: string,
    eventLabel: DropdownItemValue
) => {
    return {
        eventCategory,
        eventAction,
        eventLabel
    };
};
