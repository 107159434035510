export const fileTypeCheck = (value: string) => {
    if (!value) {
        return null;
    }
    const splitName = value.split('\\');
    const fileEnd = splitName[splitName.length - 1];
    const endSplit = fileEnd.split('.');
    const fileType = endSplit[endSplit.length - 1];
    const fileTypeCased = fileType.toLowerCase();
    if (
        fileTypeCased === 'doc' ||
        fileTypeCased === 'docx' ||
        fileTypeCased === 'pdf'
        ) {
            return true;
        } else {
            return false;
        }
}