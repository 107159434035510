import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { AppRoutingModule } from './app-routing.module';
import { WINDOW_PROVIDERS } from './services/window.service';

import { CurrencyPipe } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { AppComponent } from './app.component';
import { AmenitiesMapModalComponent } from './common/amenities-map-modal/amenities-map-modal.component';
import { AmenityCardComponent } from './common/amenity-card/amenity-card.component';
import { BlogCardHorizontalComponent } from './common/blog-card-horizontal/blog-card-horizontal.component';
import { BlogCardVerticalComponent } from './common/blog-card-vertical/blog-card-vertical.component';
import { CalcDefinitionsComponent } from './common/calc-definitions/calc-definitions.component';
import { CommunityCardComingSoonHorizontalComponent } from './common/community-card-coming-soon-horizontal/community-card-coming-soon-horizontal.component';
import { CommunityCardHorizontalComponent } from './common/community-card-horizontal/community-card-horizontal.component';
import { CommunityCardVerticalComponent } from './common/community-card-vertical/community-card-vertical.component';
import { DropdownComponent } from './common/dropdown/dropdown.component';
import { ErrorContentComponent } from './common/error-content/error-content.component';
import { FormSubmissionModalComponent } from './common/form-submission-modal/form-submission-modal.component';
import { FullScreenSliderComponent } from './common/full-screen-slider/full-screen-slider.component';
import { GalleryModalComponent } from './common/gallery-modal/gallery-modal.component';
import { HeroComponent } from './common/hero/hero.component';
import { LogoAnimatedComponent } from './common/logo-animated/logo-animated.component';
import { MapCardSimpleComponent } from './common/map-card-simple/map-card-simple.component';
import { ModalTemplateComponent } from './common/modal/modal-template/modal-template.component';
import { MortgageCalcComponent } from './common/mortgage-calc/mortgage-calc.component';
import { NavSpacerComponent } from './common/nav-spacer/nav-spacer.component';
import { ProductPromoCardComponent } from './common/product-promo-card/product-promo-card.component';
import { PromoCardComponent } from './common/promo-card/promo-card.component';
import { SectionAreasComponent } from './common/section-areas/section-areas.component';
import { SectionFindMoreComponent } from './common/section-find-more/section-find-more.component';
import { SectionIntroComponent } from './common/section-intro/section-intro.component';
import { SectionSliderHomesComponent } from './common/section-slider-homes/section-slider-homes.component';
import { SliderComponent } from './common/slider/slider.component';
import { TeamCardComponent } from './common/team-card/team-card.component';
import { AboutOverviewPageComponent } from './features/about-overview/containers/about-overview-page/about-overview-page.component';
import { AboutCardComponent } from './features/about-overview/presentational/about-card/about-card.component';
import { AboutFindMoreComponent } from './features/about-overview/presentational/about-find-more/about-find-more.component';
import { AboutIntroComponent } from './features/about-overview/presentational/about-intro/about-intro.component';
import { AboutSectionAreasComponent } from './features/about-overview/presentational/about-section-areas/about-section-areas.component';
import { AboutSliderComponent } from './features/about-overview/presentational/about-slider/about-slider.component';
import { GraphicSectionComponent } from './features/about-overview/presentational/graphic-section/graphic-section.component';
import { HistorySectionComponent } from './features/about-overview/presentational/history-section/history-section.component';
import { TeamSectionComponent } from './features/about-overview/presentational/team-section/team-section.component';
import { RanchCommunityComponent } from './features/community/containers/community/community.component';
import { RanchCommunityAmenitiesComponent } from './features/community/presentational/community-amenities/amenities.component';
import { RanchCommunityAreaMapComponent } from './features/community/presentational/community-area-map/area-map.component';
import { RanchCommunityHeaderComponent } from './features/community/presentational/community-header/header.component';
import { RanchCommunitySiteplanComponent } from './features/community/presentational/community-siteplan/siteplan.component';
import { FindAHomeComponent } from './features/find-a-home/find-a-home.component';
import { ViewHomeGalleryComponent } from './features/find-a-home/subfeatures/view-home/subfeatures/view-home-gallery/view-home-gallery.component';
import { ViewHomeComponent } from './features/find-a-home/subfeatures/view-home/view-home.component';
import { FooterComponent } from './features/footer/footer.component';
import { RanchGalleryComponent } from './features/gallery/containers/gallery/gallery.component';
import { RanchGalleryCarouselComponent } from './features/gallery/presentational/gallery-carousel/carousel.component';
import { RanchGalleryHeaderComponent } from './features/gallery/presentational/gallery-header/header.component';
import { RanchGalleryTabNavComponent } from './features/gallery/presentational/gallery-tab-nav/tab-nav.component';
import { RanchGalleryTourComponent } from './features/gallery/presentational/gallery-tour-embed/tour.component';
import { HomeComponent } from './features/home/containers/home/home.component';
import { HomeLogoTextComponent } from './features/home/presentational/home-logo-text/home-logo-text.component';
import { HomeSectionAmenitiesComponent } from './features/home/presentational/home-section-amenities/home-section-amenities.component';
import { HomeSectionAreasComponent } from './features/home/presentational/home-section-areas/home-section-areas.component';
import { HomeSectionIntroComponent } from './features/home/presentational/home-section-intro/home-section-intro.component';
import { HomeSliderComponent } from './features/home/presentational/home-slider/home-slider.component';
import { VideoModalComponent } from './features/home/presentational/video-modal/video-modal.component';
import { NavigationComponent } from './features/navigation/navigation.component';
import { MenuInterestComponent } from './features/navigation/presentational/menu-interest/menu-interest.component';
import { MenuModalComponent } from './features/navigation/presentational/menu-modal/menu-modal.component';
import { MenuSidenavComponent } from './features/navigation/presentational/menu-sidenav/menu-sidenav.component';
import { RanchHomeGalleryPreviewComponent } from './features/home/presentational/home-gallery-preview/preview.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavigationComponent,
    FooterComponent,
    HomeLogoTextComponent,
    BlogCardVerticalComponent,
    RanchGalleryComponent,
    RanchGalleryHeaderComponent,
    RanchGalleryTabNavComponent,
    RanchGalleryCarouselComponent,
    RanchGalleryTourComponent,
    CommunityCardHorizontalComponent,
    CommunityCardVerticalComponent,
    AboutOverviewPageComponent,
    HeroComponent,
    GraphicSectionComponent,
    AboutCardComponent,
    TeamSectionComponent,
    SliderComponent,
    HistorySectionComponent,
    PromoCardComponent,
    BlogCardHorizontalComponent,
    ErrorContentComponent,
    MapCardSimpleComponent,
    AmenityCardComponent,
    AmenitiesMapModalComponent,
    FormSubmissionModalComponent,
    VideoModalComponent,
    GalleryModalComponent,
    LogoAnimatedComponent,
    MenuModalComponent,
    FullScreenSliderComponent,
    TeamCardComponent,
    CommunityCardComingSoonHorizontalComponent,
    ModalTemplateComponent,
    DropdownComponent,
    ProductPromoCardComponent,
    PromoCardComponent,
    MortgageCalcComponent,
    CalcDefinitionsComponent,
    HomeSectionIntroComponent,
    HomeSectionAmenitiesComponent,
    HomeSectionAreasComponent,
    HomeSliderComponent,
    AboutIntroComponent,
    SectionIntroComponent,
    SectionSliderHomesComponent,
    AboutSliderComponent,
    SectionFindMoreComponent,
    AboutFindMoreComponent,
    MenuInterestComponent,
    SectionAreasComponent,
    AboutSectionAreasComponent,
    MenuSidenavComponent,
    FindAHomeComponent,
    NavSpacerComponent,
    RanchCommunityComponent,
    RanchCommunityHeaderComponent,
    RanchCommunityAmenitiesComponent,
    RanchCommunitySiteplanComponent,
    RanchCommunityAreaMapComponent,
    ViewHomeComponent,
    ViewHomeGalleryComponent,
    RanchHomeGalleryPreviewComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatCheckboxModule,
    MatExpansionModule,
    GoogleMapsModule,
    MatDialogModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    SelectDropDownModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    CurrencyPipe,
    WINDOW_PROVIDERS,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
