<div class="wf-dropdown">
    <mat-form-field floatLabel="never">
      <mat-label>
        <span
          class="wf-dropdown__label-text"
          [class.panel-opened]="noteSelect.panelOpen"
          [class.ut-dropdown__opacity]="placeholderOpacity"
          >{{ placeholder }}</span
        >
      </mat-label>
      <!--<div class="wf-dropdown__double-arrow" *ngIf="!hideArrows">
        <utour-icon [name]="'icons/double-arrow'"></utour-icon>
      </div>-->
      <mat-select
        [multiple]="multiple"
        [disableOptionCentering]="true"
        [value]="selectedOption"
        #noteSelect
        class="wf-dropdown__visible-option"
        [class.opened]="noteSelect.panelOpen"
        [class.dropdown-disabled]="disabled"
        [panelClass]="'wf-dropdown__multiple-panel'"
        (selectionChange)="onSelectionChange($event)"
        (openedChange)="onOpenChanged($event)"
        [tabIndex]="tabindex || 0"
        [disabled]="disabled"
      >
        <mat-option
          *ngFor="let option of options"
          class="ut-dropdown__option"
          [value]="emitOptionValue ? option.value : option"
          [class.ut-dropdown__option--disabled]="option.disabled"
          [disabled]="option.disabled"
          >{{ option.text }}</mat-option
        >
      </mat-select>
    </mat-form-field>
</div>
  