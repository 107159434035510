<div (click)="navToCommunity(communityData.id)" class="contain-card">
    <div class="card-left">
        <img src="{{communityData.mainImgSrc}}">
        <div class="contain-action">
            <div class="action-text">
                more details
            </div>
            <img src="/assets/icons/Right_Arrow_Red.svg">
        </div>
        <div *ngIf="communityData.hasSnipe" class="contain-snipe">
            <div class="snipe">
                <div class="snipe-text">
                    {{communityData.snipeText}}
                </div>
            </div>
        </div>
    </div>
    <div class="card-right">
        <div class="com-name">{{communityData.name}}</div>
        <div class="com-city">{{communityData.cityState}}</div>
        <div class="coming-soon">
            Anticipated Opening in 2022
        </div>
        <div class="data-flex top-margin">
            <div class="data-text">Single-Family Homes Priced from</div>
            <div class="data-num price">{{communityData.singleHomesData.price}}</div>
        </div>
        <div class="data-flex">
            <div class="data-text">Townhomes Priced from</div>
            <div class="data-num price">{{communityData.townHomesData.price}}</div>
        </div>
    </div>
</div>
