<div class="contain-modal">
    <div class="position-close">
        <img (click)="close()" src="/assets/icons/IconClose_White.svg">
    </div>
    <div class="content">
        <div class="head">
            Thank you
        </div>
        <div class="subhead">
            Your information has been sent successfully. We'll be in touch as soon as possible.
        </div>
        <div *ngIf="data?.forFeature === 'qmi-modal'">
            <div (click)="handleCloseModal()" class="kill-btn-outline">
                <span class="btn-text">Close and see home details</span>
            </div>
        </div>
    </div>
</div>
