import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AmenitiesService } from '../../services/amenities.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GalleryModalComponent } from '../../common/gallery-modal/gallery-modal.component';
import { ScreenSizeService } from '../../services/screen-size.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ranch-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {
  @Input() slideData: any;
  @Input() slideConfig: SwiperConfigInterface;
  @Input() customClass: string;
  @ViewChild('swiperComp', { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;
  currentIndex = 0;
  isDesktop: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private amenitiesService: AmenitiesService,
    private dialog: MatDialog,
    private screenService: ScreenSizeService
  ) {
    this.detectScreenSize();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  detectScreenSize(): void {
    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.isDesktop = res;
      if (this.customClass === 'team' || this.customClass === 'team-mobile') {
        this.setTeam(res);
      }
    });
  }

  onIndexChange(evt): void {
    this.currentIndex = evt;
    if (this.customClass === 'location-mobile') {
      this.amenitiesService.amenityIndex$.next(evt);
    }
  }

  openModal(): void {
    const modalData = {
      slideData: this.slideData,
      index: this.currentIndex
    };
    const modalConfig = new MatDialogConfig();
    modalConfig.closeOnNavigation = true;
    modalConfig.disableClose = false;
    modalConfig.panelClass = 'full-screen';
    modalConfig.backdropClass = 'backdrop-dark';
    modalConfig.data = modalData;
    const modalRef = this.dialog.open(GalleryModalComponent, modalConfig);
  }

  setTeam(isDesktop): void {
    if (isDesktop) {
      this.slideData = [
        {
          url: '/assets/images/Cliffe_Killam_FPO.jpg',
          caption1: 'Cliffe',
          caption2: 'Killam',
          caption3: 'Partner'
        },
        {
          url: '/assets/images/Andy_K_FPO.jpg',
          caption1: 'Andy',
          caption2: 'Konovodof',
          caption3: 'Division President'
        },
        {
          url: '/assets/images/Alejandra_FPO.jpg',
          caption1: 'Alejandra',
          caption2: 'Canales',
          caption3: 'Purchasing Associate'
        },
        {
          url: '/assets/images/Roland_O_FPO.jpg',
          caption1: 'Roland',
          caption2: 'Ortiz',
          caption3: 'Real Estate Manager'
        },
        {
          url: '/assets/images/Cliffe_Killam_FPO.jpg',
          caption1: 'Cliffe',
          caption2: 'Killam',
          caption3: 'Partner'
        },
        {
          url: '/assets/images/Andy_K_FPO.jpg',
          caption1: 'Andy',
          caption2: 'Konovodof',
          caption3: 'Division President'
        },
        {
          url: '/assets/images/Alejandra_FPO.jpg',
          caption1: 'Alejandra',
          caption2: 'Canales',
          caption3: 'Purchasing Associate'
        },
        {
          url: '/assets/images/Roland_O_FPO.jpg',
          caption1: 'Roland',
          caption2: 'Ortiz',
          caption3: 'Real Estate Manager'
        }
      ];
    } else {
      this.slideData = [
        {
          url: '/assets/images/Cliffe_Killam_FPO.jpg',
          caption1: 'Cliffe Killam',
          caption2: '',
          caption3: 'Partner'
        },
        {
          url: '/assets/images/Andy_K_FPO.jpg',
          caption1: 'Andy Konovodof',
          caption2: '',
          caption3: 'Division President'
        },
        {
          url: '/assets/images/Alejandra_FPO.jpg',
          caption1: 'Alejandra Canales',
          caption2: '',
          caption3: 'Purchasing Associate'
        },
        {
          url: '/assets/images/Roland_O_FPO.jpg',
          caption1: 'Rolando Ortiz',
          caption2: '',
          caption3: 'Real Estate Manager'
        },
        {
          url: '/assets/images/Cliffe_Killam_FPO.jpg',
          caption1: 'Cliffe Killam',
          caption2: '',
          caption3: 'Partner'
        },
        {
          url: '/assets/images/Andy_K_FPO.jpg',
          caption1: 'Andy Konovodof',
          caption2: '',
          caption3: 'Division President'
        },
        {
          url: '/assets/images/Alejandra_FPO.jpg',
          caption1: 'Alejandra Canales',
          caption2: '',
          caption3: 'Purchasing Associate'
        },
        {
          url: '/assets/images/Roland_O_FPO.jpg',
          caption1: 'Rolando Ortiz',
          caption2: '',
          caption3: 'Real Estate Manager'
        }
      ];
    }
  }
}
