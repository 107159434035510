import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ranch-amenities-map-modal',
  templateUrl: './amenities-map-modal.component.html',
  styleUrls: ['./amenities-map-modal.component.scss']
})
export class AmenitiesMapModalComponent implements OnInit {
  communityData: any;
  amenityData: any;
  modal = true;

  constructor(
    private dialogRef: MatDialogRef<AmenitiesMapModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    data?.amenData
      ? (this.amenityData = data.amenData)
      : (this.communityData = data?.communityData);
  }

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }
}
