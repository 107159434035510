<div class="modal-template__close-icon white-desktop">
    <img (click)="close()" src="/assets/icons/Close_X_White_wShadow.svg">
</div>
<div class="modal-template__close-icon black-mobile">
    <img (click)="close()" src="/assets/icons/Close_Xblack.svg">
</div>
<div class="contain-modal modal-template" [class.modal-template--sm-full-screen]="smFullScreen" [class.modal-template--mobile-padding]="mobilePadding" [class.modal-template--full-page-modal]="fullPageModal" [class.modal-template--max-width-modal]="maxWidth">
    <!--<div class="modal-template__close-icon" *ngIf="showCloseIcon">
      <img (click)="close()" src="/assets/icons/Close_X_White_wShadow.svg">
  </div>-->

    <header class="modal-template__header" *ngIf="header">
        <div class="modal-template__title">{{ header }}</div>
    </header>

    <section #contentWrapper class="modal-template__body">
        <div class="modal-template__contain-snipe" *ngIf="!!snipe">
            <div class="snipe">
                <div class="snipe-text"><span class="spicy" *ngIf="!!numberForSnipe">{{ numberForSnipe }}&nbsp;</span><span class="eaves">{{ snipe }}</span></div>
            </div>
        </div>
        <ng-content></ng-content>
    </section>
</div>