<div class="contain-def">
    <div class="header">Definitions</div>
    <div class="terms">
        <div class="term-group" *ngFor="let def of definitions">
            <div class="term">
                {{ def.term }}
            </div>
            <div class="term-def">{{def.definition}}</div>
        </div>
        <p class="disclaimer">The values and figures shown are hypothetical and may not be applicable to your individual situation; please consult with your lender for the most accurate terms and rates.</p>
        <button (click)="goBack()" class="btn-back">Back</button>
    </div>
</div>