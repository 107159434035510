import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { GOOGLE_TAG_MANAGER_ID } from 'src/constants/common.constants';

import { environment } from './../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SiteService {

  constructor(@Inject(DOCUMENT) private doc: Document) { }

  handleAnalyticsOnPageChange(): void {
    if (environment.production) {
      //this.addGoogleAnalyticsScript(GOOGLE_ANALYTICS_ID);
      this.addGoogleTagManagerScript(GOOGLE_TAG_MANAGER_ID);
    }
  }

  private addGoogleTagManagerScript(googleTagManagerId: string): void {
    const gtmScript = this.doc.createElement('script');
    gtmScript.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${googleTagManagerId}');`;

    const gtmNoScript = this.doc.createElement('noscript');
    const noScriptIframe = this.doc.createElement('iframe');
    noScriptIframe.setAttribute(
      'src',
      `https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`
    );
    noScriptIframe.setAttribute('height', '0');
    noScriptIframe.setAttribute('width', '0');
    noScriptIframe.setAttribute('style', 'display:none;visibility:hidden');
    gtmNoScript.append(noScriptIframe);

    this.doc.head.append(gtmScript);
    this.doc.body.prepend(gtmNoScript);
  }

  private addGoogleAnalyticsScript(googleAnalyticsId: string): void {
    const gtagScript = this.doc.createElement('script');
    gtagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`);
    gtagScript.setAttribute(
      'async',
      'true'
    );

    const gtagEventsTriggeringScript = this.doc.createElement('script');
    gtagEventsTriggeringScript.innerText = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${googleAnalyticsId}');`;

    this.doc.head.append(gtagEventsTriggeringScript);
    this.doc.head.append(gtagScript);
  }

  backToTop() {
    const wind = this.doc.defaultView as Window;
    wind && wind.scrollTo && wind.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
