import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ranch-community-card-coming-soon-horizontal',
  templateUrl: './community-card-coming-soon-horizontal.component.html',
  styleUrls: ['./community-card-coming-soon-horizontal.component.scss']
})
export class CommunityCardComingSoonHorizontalComponent implements OnInit {
  @Input() communityData: any;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navToCommunity(id: string): void {
    this.router.navigate(['/community', id], { state: { data: id } });
  }
}
