<div class="slider-cont" [ngClass]="customClass">
    <img (click)="openModal()" class="inspect-icon" src="/assets/icons/MagnifyingGlass_Icon_White_wShadow.svg">
    <div class="content-container">
        <div id="slider-id" class="swiper-container" [swiper]="slideConfig" (indexChange)="onIndexChange($event)">
            <div #swiperComp class="swiper-wrapper">
                <div *ngFor="let slide of slideData" class="swiper-slide">
                    <div class="contain-slide">
                        <div class="tint tint-left"></div>
                        <img [src]="slide?.url" />
                        <div class="tint tint-right"></div>
                        <div class="caption">
                            <div class="caption-1 top">{{ slide?.caption1 }}</div>
                            <div class="caption-2">{{ slide?.caption2 }}</div>
                            <div class="caption-3">{{ slide?.caption3 }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="slideConfig.a11y && slideData.length > 1" class="swiper-button-prev"></div>
            <div *ngIf="slideConfig.a11y && slideData.length > 1" class="swiper-button-next"></div>
            <div *ngIf="slideConfig.a11y" class="outer-tint tint-left"></div>
            <div *ngIf="slideConfig.a11y" class="outer-tint tint-right"></div>
            <div *ngIf="slideConfig.a11y" class="swiper-pagination"></div>
            <div *ngIf="slideConfig.a11y" class="tint-bottom"></div>
        </div>
    </div>
    <div *ngIf="slideConfig.a11y" class="swiper-prev-custom"></div>
    <div *ngIf="slideConfig.a11y" class="swiper-next-custom"></div>
</div>