<div #teamSection class="contain-team-section">
    <div class="team-content">
        <div class="header-all delay" id="fade-team-slide-head-0">
        <div class="header-flex">
            <div class="header">
                Shaping Texas History
            </div>
            <div class="spacer-top"></div>
        </div>
        <div *ngIf="isDesktop" class="header-flex">
            <div class="spacer"></div>
            <div class="header">
                for Over 100 Years… and
            </div>
            <div class="salsa">
                Counting
            </div>
        </div>
        <div *ngIf="!isDesktop" class="header-flex">
            <div class="header">
                for Over 100 Years…
            </div>
        </div>
        <div *ngIf="!isDesktop" class="header-flex">
            <div class="header">
                and 
            </div>
            <div class="salsa">
                Counting
            </div>
        </div>
    </div>
        <div class="subhead delay" id="fade-team-slide-subhead-1">
            <div class="subhead-text">
                The Killam Companies, the South Texas benchmark behind Winfield Communities, have been part of the fabric of the Lone Star State for generations.
            </div>
        </div>
        <div *ngIf="!isLtTablet" class="team-cards">
            <div class="card" *ngFor="let item of slideData">
                <ranch-team-card [cardData]="item"></ranch-team-card>
            </div>
        </div>
        <!-- slider -->
        <div *ngIf="isLtTablet" class="contain-slider" id="fade-team-slider-2">
            <ranch-slider [slideConfig]="slideConfig" [slideData]="slideData" [customClass]="customClass"></ranch-slider>
        </div>
        <!-- end slider -->
    </div>
</div>
