import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ranch-graphic-section',
  templateUrl: './graphic-section.component.html',
  styleUrls: ['./graphic-section.component.scss']
})
export class GraphicSectionComponent implements OnInit {
  customClass = 'about-logo';

  constructor() {}

  ngOnInit(): void {}
}
