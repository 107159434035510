import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  isMobile$ = new BehaviorSubject(false);
  isMobileLandscape$ = new BehaviorSubject(false);
  isTablet$ = new BehaviorSubject(false);
  isDesktop$ = new BehaviorSubject(false);
  isLtTablet$ = new BehaviorSubject(false);

  constructor(
    private bpObs: BreakpointObserver
  ) {
    this.bpObs.observe('(max-width: 478.98px)').subscribe(res => {
      res.matches ? this.isMobile$.next(true) : this.isMobile$.next(false);
    });
    this.bpObs.observe('(min-width: 479px) and (max-width: 766.98px)').subscribe(res => {
      res.matches ? this.isMobileLandscape$.next(true) : this.isMobileLandscape$.next(false);
    });
    this.bpObs.observe('(min-width: 767px) and (max-width: 991.98px)').subscribe(res => {
      res.matches ? this.isTablet$.next(true) : this.isTablet$.next(false);
    });
    this.bpObs.observe('(min-width: 992px)').subscribe(res => {
      res.matches ? this.isDesktop$.next(true) : this.isDesktop$.next(false);
    });
    // all less than tablet
    this.bpObs.observe('(max-width: 766.98px)').subscribe(res => {
      res.matches ? this.isLtTablet$.next(true) : this.isLtTablet$.next(false);
    });
  }
}
