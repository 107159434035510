export enum Pages {
    Home = '',
    About = 'about-overview',
    Community = 'community',
    FindAHome = 'find-a-home',
    Gallery = 'gallery',

}

const defaultTitle: string = 'The Ranch | New Homes in Rancho Cordova, CA';

export const SEOParams: Record<Pages, { title: string, body: string }> = {
    [Pages.Home]: {
        title: defaultTitle,
        body: 'Break away from the expected and turn to a new way of life. With acres of rolling land on a nature preserve in Rancho Cordova, The Ranch is an ideal place to put down roots for your next chapter. Here you can embrace a more carefree version of yourself without sacrificing the modern conveniences that make each day easier.'
    },
    [Pages.About]: {
        title: 'About Us | The Ranch',
        body: 'With over 60 years of experience in 15 unique markets across the country, K. Hovnanian® Homes is well-equipped to bring your dream home to life. We\'ll ensure your home reflects your unique style — and is built with the utmost care and quality materials. This is why K. Hovnanian® Homes is The First Name in Lasting Value.®'
    },
    [Pages.Community]: {
        title: defaultTitle,
        body: 'Introducing a master-planned community that disrupts your usual routine to deliver a more fulfilling everyday lifestyle. The Ranch seamlessly combines on-site outdoor activities with breathtaking natural scenery. Whether the day calls for a family adventure or some alone time to unwind, you can find it waiting just outside your door.'
    },
    [Pages.FindAHome]: {
        title: defaultTitle,
        body: 'Discover Dozens of Designs, Find Your One Perfect Fit'
    },
    [Pages.Gallery]: {
        title: defaultTitle,
        body: 'Browse through our gallery to preview every part of what will soon make up The Ranch. These visual tools will help you see more clearly all of the excitement that\'s to come.'
    }
}