<ng-container *ngIf="!(!communityData && !amenityData && !qmiData)">
    <ng-container *ngIf="communityData; else elseTemplate">
        <div class="contain-map-card" [ngClass]="{
            modal : modal,
            community : communityData
         }">
            <img src="/assets/icons/Close_X_Red.svg" class="close-btn"/>
            <div class="name">{{amenityData ? amenityData.name : communityData.name }}</div>
            <div class="address-contain">
                <div class="address">
                    {{ amenityData ? amenityData.address : communityData.address }}
                </div>
                <div class="address">
                    {{ amenityData ? amenityData.city + ', ' + amenityData.state + ' ' + amenityData.zip  : communityData.city  + ', ' +  communityData.state + ' ' + communityData.zip }}
                </div>
            </div>
            <div *ngIf="!modal" class="actions">
                <a href="tel:{{ amenityData ? amenityData.phone : communityData.phone }}" class="phone address">
                    {{ amenityData ? amenityData.phone : communityData.phone }}
                </a>
                <a class="directions" href="{{ amenityData ? amenityData.google_link : communityData.directionsLink }}" target="_blank">
                    <span class="direct-text">
                        Get Directions
                    </span>
                    <img src="/assets/icons/Right_Arrow_Red.svg">
                </a>
            </div>
            <div *ngIf="modal" class="modal-actions">
                <a href="{{ amenityData ?amenityData.google_link : communityData.directionsLink }}" target="_blank" class="call-btn">
                    get directions
                </a>
                <a href="tel:{{ amenityData ? amenityData.phone : communityData.phone }}" class="call-btn">
                    call now
                </a>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="contain-map-card" [ngClass]="{
            modal : modal,
            community : communityData
         }">
            <img src="/assets/icons/Close_X_Red.svg" class="close-btn"/>
            <div class="name">{{amenityData ? amenityData.name : qmiData.homePlanName }}</div>
            <div class="address-contain">
                <div class="address">
                    {{ amenityData ? amenityData.address : qmiData.address }}
                </div>
                <div class="address">
                    {{ amenityData ? amenityData.city + ', ' + amenityData.state + ' ' + amenityData.zip  : qmiData.city  + ', ' +  qmiData.state + ' ' + qmiData.zip }}
                </div>
            </div>
            <div *ngIf="!modal" class="actions">
                <a *ngIf=" " href="tel:{{ amenityData ? amenityData.phone : qmiData?.phone }}" class="phone address">
                    {{ amenityData ? amenityData.phone : communityData.phone }}
                </a>
                <a class="directions" href="{{ amenityData ? amenityData.google_link : qmiData?.directionsLink }}" target="_blank">
                    <div class="direct-text">
                        Get Directions
                    </div>
                    <img src="/assets/icons/Right_Arrow_Red.svg">
                </a>
            </div>
            <div *ngIf="modal" class="modal-actions">
                <a href="{{ amenityData ?amenityData.google_link : qmiData.directionsLink }}" target="_blank" class="call-btn">
                    get directions
                </a>
                <a href="tel:{{ amenityData ? amenityData.phone : qmiData.phone }}" class="call-btn">
                    call now
                </a>
            </div>
        </div>
    </ng-template>
</ng-container>
