import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ranch-about-intro',
  templateUrl: './about-intro.component.html',
  styleUrls: ['./about-intro.component.scss']
})
export class AboutIntroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
