<div class="gallery-container">
  <ranch-hero [hero]="hero" *ngIf="hero && !isDesktop"></ranch-hero>
  <ranch-gallery-header [taglineOnly]="!isDesktop"></ranch-gallery-header>
  <ranch-tab-nav [tabs]="tabs" [selected]="selectedTab" (onSetTab)="setTabHandler($event)">
    <ranch-gallery-carousel
      *ngIf="config.communityImages.length && selectedTab === 0"
      [images]="config.communityImages"
    ></ranch-gallery-carousel>
    <ranch-gallery-carousel
      *ngIf="config.homeDesignImages.length && selectedTab === 1"
      [images]="config.homeDesignImages"
    ></ranch-gallery-carousel>
    <ranch-gallery-tour-embed
      *ngIf="config.tourIframeUrls.length && selectedTab === 2"
      [iframeUrls]="config.tourIframeUrls"
    >
    </ranch-gallery-tour-embed>
  </ranch-tab-nav>
</div>
