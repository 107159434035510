import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ranch-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss']
})
export class TeamCardComponent implements OnInit {
  @Input() cardData: any;

  constructor() {}

  ngOnInit(): void {}
}
