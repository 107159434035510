<div id="amenities" class="amenities-layout-container">
  <div
    class="amenity-row"
    *ngFor="let amenityData of amenities; index as i"
    [ngStyle]="{ 'flex-direction': i % 2 === 0 ? 'row' : 'row-reverse' }"
  >
    <div class="amenity-image" [ngStyle]="{ 'background-image': getImageForAmenity(i) }"></div>
    <div class="amenity-description-container">
      <div class="amenity-icon-container">
        <img class="amenity-icon" [src]="amenityData.icon" />
      </div>
      <div class="amenity-heading">
        {{ amenityData.title }}
      </div>
      <div class="amenity-tagline">
        {{ amenityData.tagline }}
      </div>
    </div>
  </div>
</div>
