import { DesktopFilter, FilterNames, FilterPopupTypes } from './find-a-home.types';

export const DESKTOP_FILTERS: Array<DesktopFilter> = [
  {
    name: FilterNames.PriceRange,
    label: 'Price Range',
    width: 168,
    popupConfig: {
      type: FilterPopupTypes.Range,
      placeholder: 'Any',
      options1: [],
      options2: []
    }
  },
  {
    name: FilterNames.Beds,
    label: 'Beds',
    width: 102,
    popupConfig: {
      type: FilterPopupTypes.Boxes,
      placeholder: 'Any',
      options: []
    }
  },
  {
    name: FilterNames.Baths,
    label: 'Baths',
    width: 102,
    popupConfig: {
      type: FilterPopupTypes.Boxes,
      placeholder: 'Any',
      options: []
    }
  },
  {
    name: FilterNames.SqFt,
    label: 'Sq. Ft.',
    width: 102,
    popupConfig: {
      type: FilterPopupTypes.Range,
      placeholder: 'Any',
      options1: [],
      options2: []
    }
  },
  {
    name: FilterNames.Garages,
    label: 'Garages',
    width: 126,
    popupConfig: {
      type: FilterPopupTypes.List,
      placeholder: 'All Garages',
      options: []
    }
  },
  {
    name: FilterNames.Stories,
    label: 'Stories',
    width: 116,
    popupConfig: {
      type: FilterPopupTypes.List,
      placeholder: 'All Stories',
      options: []
    }
  },
  {
    name: FilterNames.HomeType,
    label: 'Home Type',
    width: 192,
    isFullWidth: true,
    popupConfig: {
      type: FilterPopupTypes.List,
      placeholder: 'All Home Types',
      options: []
    }
  },
  {
    name: FilterNames.Community,
    label: 'Community',
    width: 168,
    isFullWidth: true,
    popupConfig: {
      type: FilterPopupTypes.List,
      placeholder: 'All Communities',
      options: [],
      getOptions: 'aggregateSubdivisions'
    }
  }
  // {
  //   name: FilterNames.ExtraSuite,
  //   label: 'Extra Suite',
  //   helper:
  //   // TODO: implement helper text if we do indeed need these checkboxes
  //     'A suite with a bedroom, bathroom, living area, kitchenette and dedicated private entry.',
  //   isCheckbox: true,
  //   popupConfig: {}
  // },
  // {
  //   name: FilterNames.MainLevelOwners,
  //   label: "Main-Level Owner's Suite",
  //   isCheckbox: true,
  //   popupConfig: {}
  // }
];
