<div (click)="getBlogDetails(blogData.blogId)" class="contain-blog-card">
    <div class="contain-blog-img">
        <img src="{{blogData.blogSrc}}">
    </div>
    <div class="contain-content">
        <div class="blog-head">{{header}}</div>
        <div class="blog-content">{{ content}}</div>
        <!-- TO DO: ADD ROUTERLINK -->
        <div class="blog-action">
            <div class="action-text">read more</div>
            <img src="/assets/icons/Right_Arrow_White.svg">
        </div>
    </div>
</div>
