<div class="contain-map-card" [ngClass]="{ modal : modal }">
    <img src="/assets/logos/Winfield_Logo_Full_Color.svg">
    <a target="_blank" href="https://www.google.com/maps?saddr=My+Location&daddr=4320+University+Boulevard+Laredo+TX+78041" class="address-contain">
        <div class="address">
            4320 University Blvd.
        </div>
        <div class="address">
            Laredo, TX 78041
        </div>
    </a>
    <div class="phone">
        <a href="tel:956-724-7141" *ngIf="!modal" class="address">
            956.724.7141
        </a>
    </div>
    <a href="tel:956-724-7141" *ngIf="modal" class="call-btn">
        call now
    </a>
</div>
