import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService implements OnDestroy {
  anchorScroll$ = new BehaviorSubject(false);
  anchorScrollMap$ = new BehaviorSubject(false);
  scrollFreeze$ = new BehaviorSubject(false);
  navOpaque$ = new BehaviorSubject(false);
  communityIndex$ = new BehaviorSubject<number>(null);
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  scrollTopOnNavigation(): void {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(e => {
        setTimeout(() => {
          this.viewportScroller.scrollToPosition([0, 0]);
        }, 0);
      });
  }

  toggleScrollFreeze(state): void {
    this.scrollFreeze$.next(state);
  }

  toggleNavOpacity(state): void {
    this.navOpaque$.next(state);
  }
}
