import { Component, ViewChild, AfterViewInit, Input, forwardRef } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface DropdownItem {
  text: string;
  value: string;
  disabled?: boolean;
  payload?: any;
}

const DROPDOWN_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownComponent),
  multi: true
};

@Component({
  selector: 'ranch-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [DROPDOWN_VALUE_ACCESSOR]
})
export class DropdownComponent implements AfterViewInit, ControlValueAccessor {
  @Input() multiple: boolean;
  @Input() placeholder = '';
  @Input() emitOptionValue: boolean;
  @Input() options: DropdownItem[] = [];
  @Input() placeholderOpacity: boolean;
  @Input() tabindex: number;
  @Input() disabled: boolean;
  @Input() hideArrows: boolean;
  @ViewChild(MatSelect, { static: true }) noteSelect: MatSelect;

  selectedOption: any = '';
  propagateChange = (_: any) => {};
  onTouched = () => {};
  onChange = (value: any) => {};

  ngAfterViewInit(): void {
    /*this.noteSelect.overlayDir.offsetY = 10;
    this.noteSelect.overlayDir.offsetX = 0;
    this.noteSelect.overlayDir.minWidth = '300px';
    this.noteSelect.overlayDir.positions = [
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top'
      }
    ];*/
  }

  writeValue(value: string | Array<any>): void {
    if (this.multiple && value instanceof Array) {
      this.selectedOption = value;
      this.onChange(value);
      return;
    }
    this.selectedOption = value;
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.noteSelect.setDisabledState(isDisabled);
  }

  onSelectionChange(option: MatSelectChange): void {
    if (this.multiple && option.value.some(val => val === null)) {
      this.selectedOption = [null];
      this.propagateChange(this.selectedOption);
      this.noteSelect.close();
      return;
    }
    this.selectedOption = option.value;
    this.propagateChange(this.selectedOption);
  }

  onOpenChanged(isOpen: boolean): void {
    if (!isOpen) {
      this.onTouched();
    } else {
      this.changePanelParams();
    }
  }

  private changePanelParams(): void {
    const bodyClientRect = document.body.getBoundingClientRect();
    const elemClientRect = this.noteSelect.trigger.nativeElement.getBoundingClientRect();
    const difference = bodyClientRect.bottom - elemClientRect.bottom;
    if (difference < 280) {
      (document.querySelector('.mat-select-panel') as HTMLDivElement).style.maxHeight =
        difference - 10 + 'px';
    }
  }
}
