<ranch-hero *ngIf="hero" [hero]="hero"></ranch-hero>
<div class="vh-content-container">
  <div class="vh-facts-box">
    <div class="vh-facts-box-icons">
      <div class="vh-facts-box-icon-box">
        <img
          src="{{
            S3_URL
          }}images/3_final_files/Website+Assets+Phase+2-3/Icons/Home+Details/Beds.svg"
          alt="icon"
        />
        <div class="vh-facts-box-icon-box-text">{{ data?.Bedrooms }} Beds</div>
      </div>
      <div class="vh-facts-box-icon-box">
        <img
          src="{{
            S3_URL
          }}images/3_final_files/Website+Assets+Phase+2-3/Icons/Home+Details/Baths.svg"
          alt="icon"
        />
        <div class="vh-facts-box-icon-box-text">{{ data?.Baths }} Baths</div>
      </div>
      <div class="vh-facts-box-icon-box">
        <img
          src="{{
            S3_URL
          }}images/3_final_files/Website+Assets+Phase+2-3/Icons/Home+Details/SqFt.svg"
          alt="icon"
        />
        <div class="vh-facts-box-icon-box-text">
          {{ data?.BaseSqft | number : '1.0-0' }} sq. ft.
        </div>
      </div>
      <div class="vh-facts-box-icon-box">
        <img
          src="{{
            S3_URL
          }}images/3_final_files/Website+Assets+Phase+2-3/Icons/Home+Details/Garage.svg"
          alt="icon"
        />
        <div class="vh-facts-box-icon-box-text">{{ data?.Garage }}-Car Garage</div>
      </div>
    </div>
    <div class="vh-facts-box-text">
      <span [innerHTML]="isReadMoreOpen ? getDescription(true) : getDescription()"></span>{{ ' '
      }}<span *ngIf="includeReadMore" class="read-more" (click)="handleClickReadMore()"
        >READ MORE</span
      >
    </div>
  </div>
  <view-home-gallery [config]="galleryConfig"></view-home-gallery>
  <div
    class="details-callout"
    [style]="
      'background-image: url(' +
      S3_URL +
      'images/3_final_files/Website+Assets+Phase+2-3/Photos/Body+Images/Model+Home+Page/' +
      (isDesktop ? 'Model-BodyImage-Desktop.png' : 'Model-BodyImage-Mobile.png') +
      ')'
    "
  >
    <div class="details-callout-heading">Ready to see all the details of our {{ this.data?.PlanName }} design?</div>
    <div class="details-callout-content">
      You can explore the full floor plan and request additional information about the {{ this.data?.PlanName }} online
      now.
    </div>
    <button class="details-callout-button" (click)="goToDetailsOnKhovDotCom()">
      Get the Details on khov.com
    </button>
  </div>
  <div class="vh-back-button-container">
    <button class="vh-back-button" (click)="handleClickBackButton()">Back</button>
  </div>
</div>
