import { ModalService } from './../modal.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ranch-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss']
})
export class ModalTemplateComponent {
  @Input() header: string;
  @Input() smFullScreen: boolean;
  @Input() fullPageModal: boolean;
  @Input() showCloseIcon: boolean;
  @Input() numberForSnipe: number;
  @Input() snipe: string;
  @Input() maxWidth: boolean;
  @Input() mobilePadding: boolean;
  @Output() toggleMenu: EventEmitter<boolean> = new EventEmitter();

  constructor(private modal: ModalService) {}

  close(): void {
    this.toggleMenu.emit(false);
    this.modal.close();
  }
}
