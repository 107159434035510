// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_STEM: 'https://post.ndgcommunications.com/khov-the-ranch',
  S3_URL: 'https://the-ranch-frontend-assets-dev.s3.amazonaws.com/',
  BDX_URL: 'https://khov-bdx-conversion.s3.amazonaws.com/bdx-khov.json',
  KHOV_SITE_STEM:
    'https://www.khov.com/find-new-homes/california/rancho-cordova/95742/k-hovnanian-homes'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
